import React from "react";
import { Table } from "antd";
import apiConfig from "../../api_config.json";
import { AppContext } from "../../context";
import {
  Breadcrumb,
  Button,
  Space,
  Typography,
  Tag,
  Alert,
  Result,
} from "antd";
import {
  PieChartOutlined,
  DatabaseOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict";
import differenceInMinutes from "date-fns/differenceInMinutes";
import { Link } from "react-router-dom";

const { Title } = Typography;

const SINGLE_COLLECTION = "Singola Collection";
const FULL_DB = "Intero Database";

const columns = [
  {
    title: "Nome",
    dataIndex: "name",
  },
  {
    title: "S3 Key",
    dataIndex: "Key",
  },
  {
    title: "Orario",
    dataIndex: "timePretty",
  },
  {
    title: "Ultima Modifica",
    dataIndex: "LastModified",
    sorter: true,
  },
  {
    title: "Size",
    dataIndex: "sizePretty",
  },
  {
    title: "Recente",
    dataIndex: "recent",
    render: (value) => (
      <Tag color={value ? "green" : "default"} key={value}>
        {value ? "NEW" : "OLD"}
      </Tag>
    ),
  },
];

export default class RestoreBackups extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      restoreType: "",
      fullDbBackups: [],
      collectionBackups: [],
      loading: false,
      selectedKey: "",
      selectedCollection: "",
      restoreLaunched: false,
      error: false,
    };
  }

  bytesToSize = (bytes) => {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (!bytes) {
      return "0 Byte";
    }
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  };

  componentDidMount() {
    this.callApi();
  }

  handleTableChange = () => {
    this.callApi();
  };

  isRecent = (dateStr) => {
    const recentBackupTreshold = 60; // 60 MINS
    const diff = differenceInMinutes(new Date(), new Date(dateStr));
    const isRecent = diff < recentBackupTreshold;
    return isRecent;
  };

  callApi = () => {
    this.setState({ loading: true });
    const url = `${
      apiConfig.mongo[this.context.isProd ? "prod" : "dev"]
    }/backups`;

    fetch(url, {
      headers: {
        Authorization: `Bearer ${this.props.accessToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        response.json().then(({ data }) => {
          const fullDbBackups = data.fullDbBackups.map((obj) => ({
            ...obj,
            sizePretty: this.bytesToSize(obj.Size),
            name: FULL_DB,
            recent: this.isRecent(obj.LastModified),
            timePretty: formatDistanceToNowStrict(new Date(obj.LastModified)),
          }));

          const collectionBackups = data.collectionBackups.map((obj) => ({
            ...obj,
            sizePretty: this.bytesToSize(obj.Size),
            name: obj.Key.split("/")[2],
            recent: this.isRecent(obj.LastModified),
            timePretty: formatDistanceToNowStrict(new Date(obj.LastModified)),
          }));

          this.setState({
            loading: false,
            fullDbBackups,
            collectionBackups,
          });
        });
      })
      .catch((error) => {
        this.setState({
          error,
        });
      });
  };

  restoreBackup = () => {
    this.setState({ loading: true });
    const url = `${
      apiConfig.mongo[this.context.isProd ? "prod" : "dev"]
    }/restore`;

    const payload = {
      objectKey: this.state.selectedKey,
    };

    if (this.state.restoreType === SINGLE_COLLECTION) {
      payload.collection = this.state.selectedCollection;
    }

    fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${this.props.accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        response.json().then((response) => {
          this.setState({
            loading: false,
            restoreLaunched: true,
          });
        });
      })
      .catch((error) => {
        this.setState({
          error,
        });
      });
  };

  render() {
    const { fullDbBackups, collectionBackups, loading } = this.state;
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({ selectedKey: selectedRowKeys[0] });
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === "Disabled User", // Column configuration not to be checked
        name: record.name,
      }),
    };

    if (this.state.error) {
      return (
        <Result
          status="error"
          title="Al momento non è possibile ripristinare il backup"
          subTitle={`Qualcosa è andato storto: ${this.state.error}`}
        />
      );
    }

    if (this.state.restoreLaunched) {
      return (
        <Result
          status="success"
          title="Ripristino del backup lanciato con successo"
          subTitle="L'operazione pu&ograve; richiedere alcuni minuti per completare."
          extra={[
            <Link to="/db/backup/restore">
              <Button
                type="primary"
                onClick={() =>
                  this.setState({
                    restoreType: "",
                    selectedKey: "",
                    restoreLaunched: false,
                  })
                }
              >
                Esegui un altro ripristino
              </Button>
            </Link>,
            <Link to="/">
              <Button>Torna alla home</Button>
            </Link>,
          ]}
        />
      );
    }

    return (
      <div style={{ margin: "16px 16px" }}>
        <Breadcrumb style={{ marginBottom: "20px" }}>
          <Breadcrumb.Item>Backup</Breadcrumb.Item>
          <Breadcrumb.Item>Ripristino</Breadcrumb.Item>
          <Breadcrumb.Item>
            {this.context.isProd ? "Production" : "Development"}
          </Breadcrumb.Item>
          <Breadcrumb.Item>{this.state.restoreType}</Breadcrumb.Item>
        </Breadcrumb>
        <Title level={2}>Cosa vuoi ripristinare?</Title>
        <Space>
          <Button
            onClick={() => this.setState({ restoreType: SINGLE_COLLECTION })}
            type="primary"
            shape="round"
            icon={<PieChartOutlined />}
            size="large"
          >
            {SINGLE_COLLECTION}
          </Button>
          <Button
            onClick={() => this.setState({ restoreType: FULL_DB })}
            type="primary"
            shape="round"
            icon={<DatabaseOutlined />}
            size="large"
          >
            {FULL_DB}
          </Button>
        </Space>
        {this.state.restoreType && (
          <div style={{ marginTop: "40px" }}>
            <Title level={2}>Ripristino {this.state.restoreType}</Title>
            <Table
              rowSelection={{
                type: "radio",
                ...rowSelection,
              }}
              columns={columns}
              rowKey={(record) => record.Key}
              dataSource={
                this.state.restoreType === SINGLE_COLLECTION
                  ? collectionBackups
                  : fullDbBackups
              }
              loading={loading}
              onChange={this.handleTableChange}
            />
          </div>
        )}
        {this.state.selectedKey && (
          <div>
            <Space direction="vertical">
              <Title level={2}>Conferma</Title>
              {this.context.isProd && (
                <Alert
                  message="Attenzione! Stai per ripristinare il database in produzione"
                  type="warning"
                />
              )}
              <Button
                onClick={this.restoreBackup}
                type="primary"
                shape="round"
                icon={<PlayCircleOutlined />}
                size="large"
              >
                Ripristina Backup {this.state.restoreType}
              </Button>
            </Space>
          </div>
        )}
      </div>
    );
  }
}
