import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Modal,
  Button,
  Tooltip,
  Badge,
  Tag,
  Timeline,
  Skeleton,
} from "antd";
import formatDistance from "date-fns/formatDistance";
import { it } from "date-fns/locale";
import "moment/locale/it";
import { useAuth0 } from "../../react-auth0-spa";
import config from "../../api_config.json";
import { useAppContext } from "../../context";
import fromUnixTime from "date-fns/fromUnixTime";
import format from "date-fns/format";
import ReactJson from "react-json-view";
import {
  CloseCircleOutlined,
  UserSwitchOutlined,
  SearchOutlined,
} from "@ant-design/icons";

const appColors = {
  ofc: "purple",
  eterno: "purple",
  giotto: "geekblue",
};

const actionColors = {
  startup: "lime",
  removebg: "lime",
  login: "lime",
  create: "green",
  update: "cyan",
  delete: "orange",
};

const now = new Date();

const makeTitle = (item) => {
  return (
    <>
      {item.action && (
        <Tag color={item.error ? "volcano" : actionColors[item.action]}>
          {item.action.toUpperCase()}
          {item.objectClass ? `: ${item.objectClass}` : ""}
        </Tag>
      )}
      {item.timestamp && (
        <Tooltip
          title={
            item.timestamp
              ? format(fromUnixTime(item.timestamp), "dd/MM/yyyy - HH:mm") + ' UTC'
              : ""
          }
        >
          <Tag>
            {formatDistance(fromUnixTime(item.timestamp), now, {
              locale: it,
            })}
          </Tag>
        </Tooltip>
      )}
    </>
  );
};

const TabOrgTimeline = (props) => {
  const { orgId } = props;
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const { isProd } = useAppContext();
  const { getTokenSilently } = useAuth0();

  const [isModalVisible, setIsModalVisible] = useState([]);

  const showModal = (index) => () => {
    isModalVisible[index] = true;
    setIsModalVisible([...isModalVisible]);
  };

  const handleCancel = (index) => () => {
    isModalVisible[index] = false;
    setIsModalVisible([...isModalVisible]);
  };

  const refreshData = () => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const token = await getTokenSilently();

        const params = {
          orgId
        };

        const response = await fetch(
          `${config.baseUrl[isProd ? "prod" : "dev"]}/events?` + new URLSearchParams(params),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const responseData = await response.json();

        return responseData;
      } catch (error) {
        console.error(error);
      }
    };

    fetchData().then((data = []) => {
      setItems(data);
      setLoading(false);
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const token = await getTokenSilently();

        const params = {
          orgId
        };

        const response = await fetch(
          `${config.baseUrl[isProd ? "prod" : "dev"]}/events?` + new URLSearchParams(params),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const responseData = await response.json();

        return responseData;
      } catch (error) {
        console.error(error);
      }
    };

    fetchData().then((data = []) => {
      setItems(data);
      setLoading(false);
    });
  }, [orgId, getTokenSilently, isProd]);

  if (loading) {
    return <Skeleton loading={loading} />;
  }

  const onCopy = (copy) => {
    const container = document.createElement('textarea')
    const val = copy.src

    container.innerHTML = typeof(val) === 'string' ? val : JSON.stringify(
        val,
        null,
        '  '
    )

    document.body.appendChild(container)
    container.select()
    // copy the same quote-less value back to the clipboard
    document.execCommand('copy')
    document.body.removeChild(container)
}

  return (
    <>
      <Row gutter={9} style={{ marginTop: "20px" }}>
        <Col>
          <Button onClick={refreshData}>Refesh Data</Button>
        </Col>
      </Row>
      <Row gutter={9} style={{ marginTop: "20px" }}>
        <Col span={9}>
          <Timeline mode="left">
            {items.map((item, index) => {
              if (Object.keys(item).length === 0) {
                return "";
              }

              if (`${item.timestamp}`.length === 13) {
                item.timestamp = Math.floor(item.timestamp / 1000);
              }

              let icon;

              if (item.error) {
                icon = <CloseCircleOutlined />;
              }

              if (item.action) {
                icon = <UserSwitchOutlined />;
              }

              isModalVisible.push(false);

              const application = item.application || "System Event";

              return (
                <Timeline.Item
                  key={index}
                  color={item.error ? "red" : actionColors[item.action]}
                  dot={icon}
                  label={
                    <Badge dot={item.error} offset={[0,10]}>
                    <Tag color={appColors[application]}>
                      {application.toUpperCase()}
                    </Tag>
                    </Badge>
                  }
                >
                  {makeTitle(item)}
                  <Button
                    onClick={showModal(index)}
                    size="small"
                    shape="circle"
                    icon={<SearchOutlined />}
                  />
                  <Modal
                    title="Event Details"
                    visible={isModalVisible[index]}
                    footer={null}
                    onCancel={handleCancel(index)}
                  >
                    <ReactJson src={item} name={null} enableClipboard={onCopy} />
                  </Modal>
                </Timeline.Item>
              );
            })}
          </Timeline>
        </Col>
      </Row>
    </>
  );
};

export default TabOrgTimeline;
