import React from "react";
import GiottoAnnunciFunebri from '../Giotto/GiottoAnnunciFunebri'

const TabAnnunciFunebri = (props = {}) => {
  return (
    <GiottoAnnunciFunebri orgId={props.orgId} />
  );
};

export default TabAnnunciFunebri;
