import React from "react";
import { Form } from "antd";
import { SelectOrg } from "../SelectOrg";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

const AbbinaClienteForm = (props) => {
  return (
    <Form
      {...layout}
      form={props.form}
      initialValues={{}}
      onFinish={props.onFinish}
    >
      <Form.Item
        label="Cliente"
        name="orgId"
        rules={[{ required: true, message: "Seleziona un cliente" }]}
      >
        <SelectOrg />
      </Form.Item>
    </Form>
  );
};

export default AbbinaClienteForm;
