import React, { useState, useEffect } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Button, Checkbox, Form, Input } from "antd";
import ReactJson from "react-json-view";
import { useAuth0 } from "../../react-auth0-spa";
import config from "../../api_config.json";
import { useAppContext } from "../../context";

const paymentMethods = ["card", "sepa_debit"];

const onCopy = (copy) => {
  const container = document.createElement("textarea");
  const val = copy.src;

  container.innerHTML =
    typeof val === "string" ? val : JSON.stringify(val, null, "  ");

  document.body.appendChild(container);
  container.select();
  // copy the same quote-less value back to the clipboard
  document.execCommand("copy");
  document.body.removeChild(container);
};

const CreatePaymentPage = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState();

  const { getTokenSilently } = useAuth0();
  const { isProd } = useAppContext();

  const callCreatePaymentPageApi = async (payload) => {
    setLoading(true);
    try {
      const token = await getTokenSilently();

      const response = await fetch(
        `${config.baseUrl[isProd ? "prod" : "dev"]}/stripe/checkout-session`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      );

      const responseData = await response.json();

      return responseData;
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        setResponse(undefined);
        const quoteUrlKey = values.hubspotQuoteUrl.split("/").pop();
        form.resetFields();
        callCreatePaymentPageApi({
          quoteUrlKey,
          payment_method_types: values.paymentMethods,
        }).then((res) => {
          setLoading(false);
          setResponse(res);
        });
      })
      .catch((info) => {
        console.error("Validate Failed:", info);
      });
  };

  useEffect(() => {
    form.setFieldsValue({ paymentMethods });
  });

  return (
    <div style={{ margin: "16px 16px" }}>
      {loading && <LinearProgress />}

      {loading === false && (
        <Form
          form={form}
          initialValues={{ hubspotQuoteUrl: "" }}
          onFinish={onSubmit}
        >
          <Form.Item
            label="URL Preventivo HubSpot"
            name="hubspotQuoteUrl"
            rules={[{ required: true, message: "Enter the HubSpot quote URL" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Metodi di Pagamento"
            name="paymentMethods"
            rules={[
              {
                required: true,
                message: "Scegli almeno un metodo di pagamento",
              },
            ]}
          >
            <Checkbox.Group options={["card", "sepa_debit"]} />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      )}

      {response && (
        <ReactJson src={response} name={null} enableClipboard={onCopy} />
      )}
    </div>
  );
};

export default CreatePaymentPage;
