import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  Badge,
  Card,
  Typography,
  Divider,
  Space,
  message,
  Button,
} from "antd";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useAuth0 } from "../../react-auth0-spa";
import config from "../../api_config.json";
import { useAppContext } from "../../context";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { LazyLoadImage } from "react-lazy-load-image-component";
import _ from "lodash";

const { Title } = Typography;
const { Meta } = Card;

const GiottoAssetOrganiser = (props) => {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [isOrderChanged, setOrderChanged] = useState(false);
  const { isProd } = useAppContext();

  const { getTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await getTokenSilently();

        const response = await fetch(
          `${config.baseUrl[isProd ? "prod" : "dev"]}/objects/${
            props.collection
          }`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const responseData = await response.json();

        return responseData;
      } catch (error) {
        console.error(error);
      }
    };

    fetchData().then(({ data }) => {
      const sortedItems = _.orderBy(data, ["order"], ["asc"]);
      setItems(sortedItems);
      setLoading(false);
    });
  }, [getTokenSilently, isProd, props]);

  const updateItemOrder = async (objectClass, objectId, order) => {
    const token = await getTokenSilently();

    const fields = [
      {
        key: "order",
        value: order,
      },
    ];

    const response = await fetch(
      `${
        config.baseUrl[isProd ? "prod" : "dev"]
      }/objects/${objectClass}/${objectId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields,
        }),
      }
    );

    await response.text();
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    setOrderChanged(true);
    return result;
  };

  const grid = 8;

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "",

    // styles we need to apply on draggables
    ...draggableStyle,
  });
  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: grid,
    width: 250,
  });

  const onDragEnd = async (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );
    setItems(newItems);
  };

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const saveNewOrder = async () => {
    message.loading("Aggiornamento in corso. Ci vorranno alcuni minuti. Non chiudere la pagina", 0);
    setOrderChanged(false);

    try {
      for (let index = 0; index < items.length; index++) {
        await updateItemOrder(props.collection, items[index].objectId, index);
        await sleep(100);
      }
    } catch (error) {
      message.error("Si é verificato un problema: " + error.message);
      console.error(error);
      setOrderChanged(true);
    }

    message.destroy();
    message.success("Ordine aggiornato con successo");
  };

  return (
    <div style={{ margin: "16px 16px" }}>
      <div>{loading && <LinearProgress />}</div>
      <Breadcrumb style={{ marginBottom: "20px" }}>
        <Breadcrumb.Item>Giotto</Breadcrumb.Item>
        <Breadcrumb.Item>{props.collection}</Breadcrumb.Item>
      </Breadcrumb>
      <Divider />
      <Title level={3} style={{ marginBottom: "40px" }}>
        <Space>
          Assets Organiser - {props.collection}
          <Badge overflowCount={5000} count={items.length}></Badge>
        </Space>
      </Title>
      <Divider />
      <Button
        disabled={!isOrderChanged}
        style={{ marginBottom: "20px" }}
        type="primary"
        size="large"
        onClick={saveNewOrder}
      >
        Salva
      </Button>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {items.map((item, index) => (
                <Draggable
                  key={item.objectId}
                  draggableId={item.objectId}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <Card style={{ textAlign: "center" }}>
                        <LazyLoadImage
                          style={{
                            marginBottom: "5px",
                            width: "auto",
                            maxHeight: "100px",
                          }}
                          src={item[props.fileColName].url}
                          alt={item.objectId}
                        />
                        <Meta
                          style={{ marginTop: "5px" }}
                          description={
                            item.objectId +
                            " " +
                            item.tags.map((tag) => tag.name).join(", ")
                          }
                        />
                      </Card>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default GiottoAssetOrganiser;
