import React, { useState } from "react";
import { formatDistance } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import { useAuth0 } from "../../react-auth0-spa";
import { useAppContext } from "../../context";
import config from '../../api_config.json';
import './ProjectBuild.css';

const useStyles = makeStyles(theme => ({
  fabProgress: {
    color: green[500]
  },
}));

function Retry(props) {
  const [ retrying, setRetrying ] = useState(false);
  const { getTokenSilently } = useAuth0();
  const classes = useStyles();
  const { isProd } = useAppContext();

  const retryBuild = async payload => {
    setRetrying(true);

    try {
      const token = await getTokenSilently();

      await fetch(`${config.ci[isProd ? 'prod' : 'dev']}/retryBuild`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(payload)
      });
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Button
    style={{marginLeft:'10px'}}
    variant="contained"
    color="primary"
    disabled={retrying}
    onClick={() =>
      retryBuild(props)
    }>
      {retrying ? <CircularProgress size={24} className={classes.fabProgress} /> : 'Retry'}
    </Button>
  )
}

class ProjectBuild extends React.Component {
  makeCodeBuildLink(region, project, id) {
    return `https://${region}.console.aws.amazon.com/codesuite/codebuild/projects/${project}/build/${id}/log?region=${region}`
  }

  render() {
    const { projectName, hash, id, region, buildStatus, executionTime, endTime } = this.props;
    const className = `ProjectBuild ${buildStatus}`;
    const externalUrl = this.makeCodeBuildLink(region, projectName, id);

    return (
      <div className={className}>
        <h1>{projectName}</h1>
        <div className="ProjectBuild-detailsTable">
          { typeof endTime === 'string' && <div>Last: <strong>{formatDistance(new Date(endTime), new Date(),  { addSuffix: true })}</strong></div>}
          <div>Duration: <strong>{executionTime} min</strong></div>
        </div>

        <span className="ProjectBuild-link">
          <a href={externalUrl} target="_blank" rel="noopener noreferrer">
            <Button variant="contained" color="primary" >Open</Button>
          </a>

          {(buildStatus === 'failed' || buildStatus === 'stopped') && <Retry projectName={projectName} region={region} hash={hash} />}
        </span>
      </div>
    );
  }
}

export default ProjectBuild;
