import React, { useState, useCallback, useEffect } from "react";
import { useAuth0 } from "../../react-auth0-spa";
import { Form, Modal, Spin } from "antd";
import config from "../../api_config.json";
import { useAppContext } from "../../context";
import axios from "axios";
import AbbinaClienteForm from "./AbbinaClienteForm";

const mutationAbbinaCliente = `
mutation AbbinaCliente($id: ID!, $fields: UpdateClienteFieldsInput) {
  updateCliente(input: {id: $id, fields: $fields}) {
    cliente {
      objectId
    }
  }
}`

  .replace(/\r?\n|\r/g, "")
  .replace(/\t/g, " ");

const mutationCreateFornitore = `
mutation CreateFornitore($fields: CreateFornitoriFieldsInput) {
    createFornitori(input: {fields: $fields}) {
      fornitori {
        objectId
      }
    }
  }`
  .replace(/\r?\n|\r/g, "")
  .replace(/\t/g, " ");

const queryAvailableFornitori = `
  query GetAvailableFornitori {
      findFeatureToggles(where: {key: {equalTo: "eterno.show.centro_prenotazioni"}}) {
      edges {
        node {
          org_pointer {
              objectId
              nome
              partita_iva
              citta
              indirizzo
              iban
              telefono1
              email
          }
        }
      }
    }
  }`
  .replace(/\r?\n|\r/g, "")
  .replace(/\t/g, " ");

const title = "Abbina Cliente";

const updateTerzistaOrg = `
  mutation updateTerzistaOrg($id: ID!) {
    updateOrganizzazione(input: {id: $id, fields: {terzista_centro_prenotazioni : true}}) {
      organizzazione {
        objectId
      }
    }
  }`;
const setAtLeastOneTerzistaInOrg = `
  mutation setAtLeastOneTerzistaInOrg($id: ID!) {
    updateOrganizzazione(input: {id: $id, fields: {atLeastOneTerzista : true}}) {
      organizzazione {
        objectId
      }
    }
  }`;

const enableFeatures = [
  { key: "prenotazioni.show", product: "eterno", disabled: false },
  { key: "prenotazioni.create", product: "eterno", disabled: false },
  { key: "prenotazioni.delete", product: "eterno", disabled: false },
  { key: "prenotazioni.send", product: "eterno", disabled: false },
  { key: "prenotazioni.update", product: "eterno", disabled: false },
];

const AbbinaClienteModal = (props) => {
  const { getTokenSilently } = useAuth0();
  const { isProd } = useAppContext();
  const [loading, setLoading] = useState(false);
  const env = isProd ? "prod" : "dev";
  const [form] = Form.useForm();
  const [availableFornitori, setAvailableFornitori] = useState([]);

  const graphqlUrl = `${config.baseUrl[env]}/graphql`;

  const createFornitore = useCallback(
    async (values) => {
      try {
        const token = await getTokenSilently();

        const fornitore = availableFornitori.find(
          (org) => org.objectId === props.orgId
        );

        if (fornitore) {
          const variables = {
            fields: {
              ragione_sociale: fornitore.nome,
              partita_iva: fornitore.partita_iva,
              citta: fornitore.citta,
              indirizzo: fornitore.indirizzo,
              iban: fornitore.iban,
              telefono: fornitore.telefono1,
              email: fornitore.email,
              centro_servizi_pointer: {
                link: props.orgId,
              },
              org_pointer: {
                link: values.orgId,
              },
            },
          };

          const response = await axios({
            method: "post",
            url: graphqlUrl,
            data: {
              query: mutationCreateFornitore,
              variables,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          return response.data;
        } else {
          return undefined;
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    [props, getTokenSilently, availableFornitori, graphqlUrl]
  );

  const fetchAvailableFornitori = useCallback(
    async (_isProd) => {
      try {
        const token = await getTokenSilently();

        const response = await axios({
          method: "post",
          url: graphqlUrl,
          data: {
            query: queryAvailableFornitori,
            variables: {},
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    [getTokenSilently, graphqlUrl]
  );

  const abbinaCliente = useCallback(
    async (values) => {
      try {
        const token = await getTokenSilently();

        const variables = {
          id: props.clienteId,
          fields: {
            invite_status: "accepted",
            terzista_org_pointer: {
              link: values.orgId,
            },
          },
        };

        const response = await axios({
          method: "post",
          url: graphqlUrl,
          data: {
            query: mutationAbbinaCliente,
            variables,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        await axios({
          method: "post",
          url: graphqlUrl,
          data: {
            query: updateTerzistaOrg,
            variables: { id: values.orgId },
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        await axios({
          method: "post",
          url: graphqlUrl,
          data: {
            query: setAtLeastOneTerzistaInOrg,
            variables: { id: props.orgId },
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        await updateServizi(
          { url: graphqlUrl, token },
          props.orgId,
          values.orgId
        );

        for (const feature of enableFeatures) {
          const token = await getTokenSilently();

          console.log(feature);

          await fetch(
            `${config.baseUrl[isProd ? "prod" : "dev"]}/setFeaturePreference/${
              props.orgId
            }`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify(feature),
            }
          );

          await fetch(
            `${config.baseUrl[isProd ? "prod" : "dev"]}/setFeaturePreference/${
              values.orgId
            }`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify(feature),
            }
          );
        }

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    [getTokenSilently, props.clienteId, props.orgId, graphqlUrl, isProd]
  );

  useEffect(() => {
    setLoading(true);
    fetchAvailableFornitori(isProd)
      .then((response) => {
        const cleanOrgs = response.map((item) => {
          return {
            ...item.org_pointer,
          };
        });
        setAvailableFornitori(cleanOrgs);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, [fetchAvailableFornitori, isProd, props.orgId]);

  return (
    <Modal
      visible={props.visible}
      title={title}
      okText="Salva"
      cancelText="Annulla"
      onCancel={() => {
        setLoading(false);
        props.onCancel();
      }}
      onOk={() => {
        setLoading(true);
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            abbinaCliente(values)
              .then(() => {
                createFornitore(values)
                  .then(() => {
                    setLoading(false);
                    props.onCreate();
                  })
                  .catch((error) => {
                    console.error(error);
                  });
              })
              .catch((error) => {
                console.error(error);
              });
          })
          .catch((info) => {
            console.error("Validate Failed:", info);
          });
      }}
    >
      {!loading && <AbbinaClienteForm form={form} />}
      {loading && <Spin size="large" />}
    </Modal>
  );
};

const findServizi = `
query FindServiziByOrgId($orgId: ID) {
  servizis(where: {
    org_pointer: {
      have: {
        objectId: {
          equalTo: $orgId
        }
      }
    }
  }, first: 10000) {
    edges {
      node {
        objectId
        ACL{
          roles{
            write
            read
            roleName
          }
        }
      }
    }
  }
}`;

const mutationServizio = `
mutation AbbinaClienteServizio($id: ID!, $fields: UpdateServiziFieldsInput) {
  updateServizi(input: {id: $id, fields: $fields}) {
    servizi{
      objectId
    }
  }
}`;

const updateServizi = async (graphql, centroServiziId, terzistaId) => {
  const response = await axios({
    method: "post",
    url: graphql.url,
    data: {
      query: findServizi,
      variables: { orgId: centroServiziId },
    },
    headers: {
      Authorization: `Bearer ${graphql.token}`,
    },
  });

  for (const servizio of response.data) {
    await axios({
      method: "post",
      url: graphql.url,
      data: {
        query: mutationServizio,
        variables: {
          id: servizio.objectId,
          fields: {
            ACL: {
              roles: [
                ...servizio.ACL.roles,
                {
                  write: true,
                  read: true,
                  roleName: terzistaId,
                },
              ],
            },
          },
        },
      },
      headers: {
        Authorization: `Bearer ${graphql.token}`,
      },
    });
  }
};

export default AbbinaClienteModal;
