const dbQueryResultsGrouped = (queryResults = {}, pref) => {
  const userPrefArray = pref.key ? pref.key.split(".") : pref.name?.split(".");
  const [group, action] = userPrefArray;
  const featurePrefId = pref.objectId;
  const featureDisabled = pref.disabled;
  const featureLabel = pref.label;
  const featureProduct = pref.product ? pref.product : pref.products;
  const featurePlans = pref.plans;

  if (!(group in queryResults)) {
    queryResults[group] = [
      {
        action,
        label: featureLabel,
        objectId: featurePrefId,
        disabled: featureDisabled,
        product: featureProduct,
        plans: featurePlans
      },
    ];
  } else {
    queryResults[group].push({
      action,
      label: featureLabel,
      objectId: featurePrefId,
      disabled: featureDisabled,
      product: featureProduct,
      plans: featurePlans

    });
  }

  return queryResults;
};

export default dbQueryResultsGrouped;
