import React, { useEffect, useState } from "react";
import { Button, Result, Card, Row, Col, Spin } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useAppContext } from "../../context";
import config from "../../api_config.json";
import ArubaCrdentialsModal from "./ArubaCrdentialsModal";
import { useAuth0 } from "../../react-auth0-spa";

const TabFatturazioneElettronica = (props) => {
  const [loading, setLoading] = useState(true);
  const [orgHasArubaCredentials, setOrgHasArubaCredentials] = useState(false);
  const [arubaModalVisible, setArubaModalVisible] = useState(false);
  const [arubaModalResult, setArubaModalResult] = useState();

  const { getTokenSilently } = useAuth0();
  const { isProd } = useAppContext();

  const onResultAcked = () => {
    setArubaModalVisible(false);
    setArubaModalResult("");
  };

  const onCreate = (values) => {
    saveArubaCredentials(values)
      .then((response) => {
        setArubaModalResult("OK");
      })
      .catch((error) => {
        console.error(error);
        setArubaModalResult("ERROR");
      });
  };

  const saveArubaCredentials = async (values) => {
    try {
      const token = await getTokenSilently();

      const payload = {
        ...values,
        orgId: props.orgId,
      };
      const response = await fetch(
        `${
          config.fatturazioneElettronica[isProd ? "prod" : "dev"]
        }/admin/credentials`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      );

      const responseData = await response.json();

      return responseData;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const hasArubaCredentials = async (values) => {
      try {
        const token = await getTokenSilently();

        const response = await fetch(
          `${
            config.fatturazioneElettronica[isProd ? "prod" : "dev"]
          }/admin/credentials?orgId=${props.orgId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const responseData = await response.json();

        return responseData;
      } catch (error) {
        console.error(error);
      }
    };

    hasArubaCredentials().then((response) => {
      setOrgHasArubaCredentials(response.orgHasCredentials);
    }).finally(() => {
        setLoading(false);
    })
  }, [getTokenSilently, isProd, props.orgId]);

  if (loading) {
      return <Spin />
  }

  return (
    <div>
      <Row gutter={9}>
        <Col span={6}>
          <Card style={{ textAlign: "center" }}>
            {orgHasArubaCredentials && <Result status="success" title="Credenziali Aruba Attive" />}
            {!orgHasArubaCredentials && <Result status="warning" title="Credenziali Aruba Mancanti" />}
            <Button
              style={{ marginTop: "15px" }}
              type="primary"
              size="large"
              onClick={() => setArubaModalVisible(true)}
            >
              Imposta Nuove Credenziali Aruba <PlusOutlined />
            </Button>
            <ArubaCrdentialsModal
              onResultAcked={onResultAcked}
              arubaModalResult={arubaModalResult}
              visible={arubaModalVisible}
              onCreate={onCreate}
              onCancel={() => setArubaModalVisible(false)}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default TabFatturazioneElettronica;
