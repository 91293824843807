import React from "react";
import apiConfig from "../../api_config.json";
import { AppContext } from "../../context";
import {
  Breadcrumb,
  Button,
  Select,
  Space,
  Typography,
  Spin,
  Steps,
  Divider,
  Alert,
  Result,
} from "antd";
import { CloudDownloadOutlined, PlayCircleOutlined } from "@ant-design/icons";

const { Step } = Steps;

const { Title, Text } = Typography;
const { Option } = Select;

class DownloadCollection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 0,
      data: [],
      loading: false,
      error: false,
      downloadUrl: "",
    };
  }

  componentDidMount() {
    this.fetchCollections();
  }

  fetchCollections = () => {
    this.setState({ loading: true });
    const url = `${
      apiConfig.mongo[this.context.isProd ? "prod" : "dev"]
    }/collections`;

    fetch(url, {
      headers: {
        Authorization: `Bearer ${this.props.accessToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        response.json().then(({ data }) => {
          const collectionsData = data.collections.map((collectionName) => ({
            text: collectionName,
            value: collectionName,
          }));
          this.setState({
            loading: false,
            data: collectionsData,
          });
        });
      })
      .catch((error) => {
        this.setState({
          error,
        });
      });
  };

  downloadCollection = () => {
    const link = document.createElement("a");
    link.href = this.state.downloadUrl;
    link.target = "_blank";
    link.setAttribute(
      "download",
      `${this.state.collection}.${Date.now()}.json`
    );
    document.body.appendChild(link);
    link.click();
  };

  backupCollection = () => {
    this.setState({ loading: true });

    const url = `${
      apiConfig.mongo[this.context.isProd ? "prod" : "dev"]
    }/download-collection`;

    const payload = {
      collection: this.state.collection,
      backupFormat: "json",
      cleanup24H: true,
    };

    fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${this.props.accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        response.json().then((data) => {
          this.setState({
            loading: false,
            step: 3,
            downloadUrl: data.message.downloadUrl,
          });
        });
      })
      .catch((error) => {
        this.setState({
          error,
        });
      });
  };

  onCollectionChange = (value) => {
    this.setState({
      collection: value,
      step: 2,
    });
  };

  render() {
    const { data, loading } = this.state;

    if (this.state.error) {
      return (
        <Result
          status="error"
          title="Al momento non è possibile creare il backup"
          subTitle={`Qualcosa è andato storto: ${this.state.error}. Se hai aperto questo link diretto, passa prima dalla homepage di MC.`}
        />
      );
    }

    return (
      <div style={{ margin: "16px 16px" }}>
        <Breadcrumb style={{ marginBottom: "20px" }}>
          <Breadcrumb.Item>Database</Breadcrumb.Item>
          <Breadcrumb.Item>Estrai Dati</Breadcrumb.Item>
          <Breadcrumb.Item>
            {this.context.isProd ? "Production" : "Development"}
          </Breadcrumb.Item>
        </Breadcrumb>
        <Divider />

        <Title level={3} style={{ marginBottom: "40px" }}>
          Estrai dati dal database
        </Title>
        <Steps progressDot current={this.state.step}>
          <Step title="1" description="Seleziona la collection" />
          <Step title="2 " description="Avvia estrazione dati" />
          <Step title="3" description="Download JSON" />
        </Steps>
        <Divider />

        <Space direction="vertical" style={{ width: "100%" }}>
          <Title level={3}>1. Scegli la collection</Title>
          <Select
            showSearch
            value={this.state.collection}
            placeholder="Select a collection"
            notFoundContent={loading ? <Spin size="small" /> : null}
            optionFilterProp="children"
            onChange={this.onCollectionChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            style={{ width: "100%" }}
          >
            {data.map((d) => (
              <Option key={d.value}>{d.text}</Option>
            ))}
          </Select>
        </Space>

        {this.state.step >= 2 && (
          <div style={{ marginTop: "40px" }}>
            <Divider />
            <Space direction="vertical">
              <Title level={3}>2. Conferma e avvia estrazione</Title>
              {this.context.isProd && (
                <Alert
                  message="Attenzione! Stai per fare una estrazione dati dell'ambiente di produzione"
                  type="warning"
                />
              )}
              <Text>
                Collection: <Text strong={true}>{this.state.collection}</Text>
              </Text>
              <Text>
                Environment:{" "}
                <Text strong={true}>
                  {this.context.isProd ? "PROD" : "DEV"}
                </Text>
              </Text>
              <Button
                loading={loading}
                onClick={this.backupCollection}
                type="primary"
                shape="round"
                icon={<PlayCircleOutlined />}
                size="large"
                disabled={this.state.step !== 2}
              >
                Avvia estrazione dati
              </Button>
            </Space>
          </div>
        )}

        {this.state.step === 3 && (
          <div style={{ marginTop: "40px" }}>
            <Divider />
            <Space direction="vertical">
              <Title level={3}>3. Scarica i dati</Title>
              <Button
                loading={loading}
                onClick={this.downloadCollection}
                type="primary"
                shape="round"
                icon={<CloudDownloadOutlined />}
                size="large"
              >
                Download
              </Button>
            </Space>
          </div>
        )}
      </div>
    );
  }
}

DownloadCollection.contextType = AppContext;

export default DownloadCollection;
