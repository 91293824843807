import React, { useState } from "react";
import { Divider, Card, Switch, Row, Col } from "antd";
import { Chart } from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { months } from '../../utils';

const { Meta } = Card;

Chart.register(ChartDataLabels);
Chart.register(annotationPlugin);

const colors = {
  2021: "red",
  2020: "blue",
  2019: "brown",
  2018: "green",
  2017: "pink",
};

const calcChartDataBillingPeriods = (org) => {
  return {
    labels: org.services_by_billing_periods.reduce((acc, billing_period) => {
      acc.push(`${billing_period.start.iso.split("-")[0]}-${billing_period.end.iso.split("-")[0]}`);
      return acc;
    }, []),
    datasets: [
      {
        label: "# di Servizi",
        data: org.services_by_billing_periods.reduce((acc, billing_period) => {
          acc.push(billing_period.total);
          return acc;
        }, []),
        fill: false,
        backgroundColor: "#165C7D",
        borderColor: "#165C7D",
        // yAxisID: "y-axis-1",
      },
    ],
  };
};

function padMissingMonths (years) {
  for (const year of years) {
    const missingMonths = 12 - year.months.length;

    if (missingMonths === 0) {
      continue;
    }

    const emptyMonths = [];
    for(let i = 0; i<missingMonths; i++) {
      emptyMonths.push({
        "count_servizi": 0,
        "count_cimiteriale": 0,
        "count_pratiche": 0,
        "total": 0
      });
    }
    year.months = [
      ...emptyMonths,
      ...year.months,
    ]
  }

  return years;
}

const calcChartDataCalendarYear = (org) => {
  return {
    labels: months,
    datasets: padMissingMonths(org.services_by_cal_years).map((year) => {
      return {
        data: year.months.map((month) => {
          return month.total;
        }),
        label: year.name,
        fill: false,
        backgroundColor: colors[year.name],
        borderColor: colors[year.name],
        // yAxisID: "y-axis-1",
        // datalabels: {
        //   color: "#fff",
        // },
      };
    }),
    // datasets: [
    //   {
    //     label: "# di Servizi",
    //     data: org.services_by_cal_years[tot - 1].months.reduce(
    //       (acc, period) => {
    //         acc.push(period.count_servizi);
    //         return acc;
    //       },
    //       []
    //     ),
    //     fill: false,
    //     backgroundColor: "#165C7D",
    //     borderColor: "#165C7D",
    //     yAxisID: "y-axis-1",
    //   },
    // ],
  };
};

const TabServizi = (props) => {
  const { org } = props;
  const [showNumbersChartMonths, setShowNumbersChartMonths] = useState(true);

  const options = {
    // annotations: [],
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {},
  };

  if (showNumbersChartMonths) {
    options.plugins.datalabels = {
      backgroundColor: function (context) {
        return context.dataset.backgroundColor;
      },
      borderRadius: 4,
      color: "white",
      font: {
        weight: "bold",
      },
      formatter: Math.round,
      padding: 6,
    };
  }

  // const options = {
  //   annotations: [],
  // };

  // if (org.limite_servizi && parseInt(org.limite_servizi) !== 500) {
  const options2 = {
    ...options,
    annotations: [
      {
        type: "line",
        mode: "horizontal",
        scaleID: "y",
        value: org.limite_servizi,
        borderColor: "rgba(255, 0, 0, 0.6)",
        borderWidth: 5,
        label: {
          backgroundColor: "rgba(255, 0, 0, 0.6)",
          content: "Limite Servizi",
          enabled: true,
        },
      },
    ],
  };
  // options2.annotations.push();
  // }

  return (
    <>
      <Row>
        <Col span={12}>
          <Card title="Servizi / Sottoscrizione" bordered={true}>
            <Bar
              height="150px"
              options={options2}
              data={calcChartDataBillingPeriods(org)}
            />
          </Card>
        </Col>
        <Col span={12}>
          {/* <Badge
            overflowCount={1000}
            count={calcChartDataCalendarYear(org).datasets[0].data.reduce(
              (acc, value) => {
                acc += value;
                return acc;
              },
              0
            )}
          > */}
          <Card title="Servizi / Mese" bordered={true}>
            <Line
              height="150px"
              data={calcChartDataCalendarYear(org)}
              options={options}
            />
            <Meta
              description={
                <>
                  {/* <Space direction="vertical"> */}
                  <Divider dashed />
                  <Row>
                    <Col span={4}>Mostra valori</Col>
                    <Col span={12}>
                      <Switch
                        defaultChecked={showNumbersChartMonths}
                        onChange={(checked) =>
                          setShowNumbersChartMonths(checked)
                        }
                      />
                    </Col>
                  </Row>
                  {/* </Space> */}
                </>
              }
            />
          </Card>
          {/* </Badge> */}
        </Col>
      </Row>
    </>
  );
};

export default TabServizi;
