import React from "react";
import {Card } from "antd";
import ReactCompareImage from "react-compare-image";

const TabImageEnhancementOrg = (props) => {
  return (
    <div>
      <div className="giotto-grid-container">
        {props.imageEnhancements.map((allegato) => {
          if (!allegato.enhancementRawImage) {
            return "";
          }

          return (
            <div className="giotto-grid-item">
              <Card>
                <div style={{  maxWidth: "25vw" }}>
                  <ReactCompareImage
                    leftImage={allegato.allegato.url}
                    rightImage={allegato.enhancementRawImage}
                  />
                </div>
              </Card>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TabImageEnhancementOrg;
