import { message } from "antd";

const updateOrgDetails = async (
  getTokenSilently,
  config,
  isProd,
  orgId,
  fields
) => {
  try {
    const token = await getTokenSilently();

    const response = await fetch(
      `${config.baseUrl[isProd ? "prod" : "dev"]}/orgs/${orgId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fields,
        }),
      }
    );

    await response.text();
    message.success("Fatto");
  } catch (error) {
    message.error("Si é verificato un problema: " + error.message);
    console.error(error);
  }
};

export default updateOrgDetails;
