import React, { useState, useEffect } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  Button,
  Space,
  Typography,
  Divider,
  Checkbox,
  Form,
  Input,
  Select,
  message,
} from "antd";
import { useAuth0 } from "../../react-auth0-spa";
import config from "../../api_config.json";
import { useAppContext } from "../../context";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useLocation } from "react-router-dom";
import { SelectOrg } from "../SelectOrg";

const { Title, Text } = Typography;

const channels = ["email", "push", "web"];
const aladinGifs = [
  "https://media.giphy.com/media/TrYXjFjgam6A0/giphy.gif",
  "https://media.giphy.com/media/3o7abldj0b3rxrZUxW/giphy.gif",
  "https://media.giphy.com/media/b5LTssxCLpvVe/giphy.gif",
  "https://media.giphy.com/media/xWGnQfQN5bauY/giphy.gif",
  "https://media.giphy.com/media/P8MxmGnjmytws/giphy.gif",
  "https://media.giphy.com/media/26tPplGWjN0xLybiU/giphy.gif",
  "https://media.giphy.com/media/3NtY188QaxDdC/giphy.gif",
  "https://media.giphy.com/media/10UeedrT5MIfPG/giphy.gif",
  "https://media.giphy.com/media/ma6P4l3O53uX6/giphy.gif",
  "https://media.giphy.com/media/wi7Bpses8HgaI/giphy.gif",
  "https://media.giphy.com/media/H5C8CevNMbpBqNqFjl/giphy.gif",
  "https://media.giphy.com/media/H5C8CevNMbpBqNqFjl/giphy.gif",
];

const SendNotifications = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const { getTokenSilently } = useAuth0();
  const { isProd } = useAppContext();

  const location = useLocation();

  const defaultValues = { channels };
  // Pre-propulate form when linked
  let initialValues = {...location.record, ...defaultValues};

  const callSendNotificationApi = async (payload) => {
    setLoading(true);
    try {
      const token = await getTokenSilently();

      await fetch(
        `${config.baseUrl[isProd ? "prod" : "dev"]}/notification`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      );

      const gif = aladinGifs[Math.floor(Math.random() * aladinGifs.length)];
      message.success(<img alt="gif" src={gif} />, 2);

    } catch (err) {
      setLoading(false);
      message.error(JSON.stringify(err, Object.getOwnPropertyNames(err)), 4);
    }
  };

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);
        callSendNotificationApi({
          ...values,
        }).then(() => {
          setLoading(false);
        });
      })
      .catch((info) => {
        console.error("Validate Failed:", info);
      });
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
  });

  return (
    <div style={{ margin: "16px 16px" }}>
      <Title level={3} style={{ marginBottom: "20px" }}>
        Invia Notifiche
      </Title>
      <Divider />
      {loading && <LinearProgress />}

      {loading === false && (
        <Form
          form={form}
          initialValues={initialValues}
          onFinish={onSubmit}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 8 }}
        >
          <Form.Item
            label="Organizzazione"
            name="orgId"
            rules={[
              { required: true, message: "Seleziona una organizzazione" },
            ]}
          >
            <SelectOrg />
          </Form.Item>
          <Form.Item
            label="Ruoli utente"
            name="roles"
            rules={[{ required: false }]}
          >
            <Select mode="tags" options={[{ value: "Amministratore" }]} />
          </Form.Item>
          <Form.Item
            label="Titolo"
            name="title"
            rules={[
              { required: true, message: "Inserisci il titolo della notifica" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Sottotitolo"
            name="subtitle"
            rules={[
              {
                required: true,
                message: "Inserisci il sottotitolo della notifica",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Contenuto"
            name="content"
            rules={[{ required: false }]}
          >
            <ReactQuill theme="snow" style={{height: '150px', marginBottom: '30px' }}/>
          </Form.Item>

          <Form.Item
            label="Canali"
            name="channels"
            rules={[
              {
                required: true,
                message: "Scegli almeno un canale",
              },
            ]}
          >
            <Checkbox.Group options={channels} />
          </Form.Item>

<Space direction="vertical">


          <Text>
            NB: Nelle notifiche mobile push, verrà inviato solo il titolo ed il
            sottotitolo
          </Text>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Invia
            </Button>
          </Form.Item>
          </Space>
        </Form>
      )}
    </div>
  );
};

export default SendNotifications;
