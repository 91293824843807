import React from "react";
import { Form } from "antd";
import { SelectOrg } from "../SelectOrg";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

const AbbinaFornitoreForm = (props) => {
  return (
    <Form
      {...layout}
      form={props.form}
      initialValues={{}}
      onFinish={props.onFinish}
    >
      <Form.Item
        label="Fornitore"
        name="orgId"
        rules={[{ required: true, message: "Seleziona un fornitore" }]}
      >
        <SelectOrg orgs={props.availableFornitori} />
      </Form.Item>
    </Form>
  );
};

export default AbbinaFornitoreForm;
