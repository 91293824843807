import React from "react";
import { Space } from "antd";
import HubspotLogo from "../../images/logos/hubspot_logo.png";
import logoStripe from "./images/stripe.png";
import ParseLogo from "./images/parse.png";
import config from "../../api_config.json";
import { useAppContext } from "../../context";

const sizes = {
  small: 20,
  large: 30,
};

const OrgLinks = (props) => {
  const { isProd } = useAppContext();
  const { org, size = "small", style } = props;

  if (org.nome) {
    const hubspotCompanyUrl = org.hs_company_id ? `https://app-eu1.hubspot.com/contacts/25262823/company/${org.hs_company_id}/` : `https://app-eu1.hubspot.com/contacts/25262823/objects/0-1/views/all/list?globalSearchQuery=${encodeURIComponent(
      org.nome
    )}`

    return (
      <Space direction="horizontal" style={style}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={hubspotCompanyUrl}
        >
          <img src={HubspotLogo} width={sizes[size]} alt="logo" />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${
            config.parseDashboard[isProd ? "prod" : "dev"]
          }/browser/organizzazione?filters=[{"field":"objectId","constraint":"eq","compareTo":"${
            org.objectId
          }"}]`}
        >
          <img src={ParseLogo} width={sizes[size]} alt="logo" />
        </a>        
        {org.stripe_customer_id && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://dashboard.stripe.com/customers/${org.stripe_customer_id}`}
          >
            <img src={logoStripe} width={sizes[size]} alt="logo" />
          </a>
        )}
      </Space>
    );
  }

  if (org.email) {
    return (
      <Space direction="horizontal" style={style}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://app-eu1.hubspot.com/contacts/25262823/objects/0-1/views/all/list?globalSearchQuery=${encodeURIComponent(
            org.email
          )}`}
        >
          <img src={HubspotLogo} width={sizes[size]} alt="logo" />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${
            config.parseDashboard[isProd ? "prod" : "dev"]
          }/browser/organizzazione?filters=[{"field":"objectId","constraint":"eq","compareTo":"${
            org.objectId
          }"}]`}
        >
          <img src={ParseLogo} width={sizes[size]} alt="logo" />
        </a>
      </Space>
    );
  }

  return <></>;
};

export default OrgLinks;
