import React, { useState, useEffect, useCallback } from "react";
import { useAuth0 } from "../../react-auth0-spa";
import { useAppContext } from "../../context";
import config from "../../api_config.json";
import { Typography, Breadcrumb, Card, Divider, Space, Tag, Badge } from "antd";
import { it } from "date-fns/locale";
import InfiniteScroll from "react-infinite-scroll-component";
import LinearProgress from "@material-ui/core/LinearProgress";
import LogoAnnunciFunebri from "../../images/logos/logo_impresa_cropped.png";
import ParseLogo from "../../images/logos/parse.png";
import formatDistance from "date-fns/formatDistance";

const { Title } = Typography;

const AnnuncioCard = ({
  objectId,
  isProd,
  title,
  url,
  photoUrl,
  createdAt,
  annuncioUrl,
}) => {
  return (
    <div className="giotto-grid-item">
      <div className="giotto-grid-item">
        <Card
          title={title}
          extra={
            <Space size={"small"}>
              {annuncioUrl && (
                <a target="_blank" rel="noopener noreferrer" href={annuncioUrl}>
                  <img src={LogoAnnunciFunebri} width={20} alt="logo" />
                </a>
              )}
              <span>|</span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${
                  config.parseDashboard[isProd ? "prod" : "dev"]
                }/browser/annunci_funebri?filters=[{"field":"objectId","constraint":"eq","compareTo":"${objectId}"}]`}
              >
                <img src={ParseLogo} width={20} alt="logo" />
              </a>
              |
              <a href={url} target="_blank" rel="noopener noreferrer">
                Org
              </a>
            </Space>
          }
        >
          <div style={{ maxWidth: "25vw" }}>
            <img src={photoUrl} width={"100%"} alt="foto" />
          </div>
          <Tag style={{ marginTop: "10px" }}>
            {formatDistance(new Date(createdAt), new Date(), { locale: it })}
          </Tag>
        </Card>
      </div>
    </div>
  );
};

const GiottoAnnunciFunebri = (props = {}) => {
  const [items, setItems] = useState([]);
  const [skipIndex, setSkipIndex] = useState(1);
  const { getTokenSilently } = useAuth0();
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const { isProd } = useAppContext();

  const fetchData = useCallback(
    async (skip = 0) => {
      const limit = 12;

      try {
        const token = await getTokenSilently();

        const endpoint = props.orgId
          ? `/annunci-funebri/${props.orgId}`
          : `/annunci-funebri?limit=${limit}&skip=${skip * limit}`;

        const response = await fetch(
          `${config.baseUrl[isProd ? "prod" : "dev"]}${endpoint}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const responseData = await response.json();
        return responseData;
      } catch (error) {
        console.error(error);
      }
    },
    [isProd, getTokenSilently, props.orgId]
  );

  useEffect(() => {
    setIsLoading(true);
    fetchData(0)
      .then(({ data }) => {
        if (data.annunciFunebri.length === 0) {
          setHasMore(false);
          setIsLoading(false);
        }
        setItems(data.annunciFunebri);
      })
      .catch((err) => {
        setItems([]);
        setIsLoading(false);
        setHasMore(false);
      });
  }, [fetchData, setItems]);

  return (
    <div style={{ margin: "16px 16px" }}>
      {!props.orgId && (
        <>
          <Breadcrumb style={{ marginBottom: "20px" }}>
            <Breadcrumb.Item>Giotto</Breadcrumb.Item>
            <Breadcrumb.Item>Annunci Funebri</Breadcrumb.Item>
          </Breadcrumb>
          <Divider />
        </>
      )}
      <Title level={3} style={{ marginBottom: "40px" }}>
        <Space>
          Annunci Funebri
          <Badge overflowCount={5000} count={items.length}></Badge>
        </Space>
      </Title>
      <Divider />
      {items && items.length === 0 && !isLoading && <p>Nessun annuncio funebre pubblicato</p>}
      <InfiniteScroll
        dataLength={items.length}
        next={() => {
          setSkipIndex(skipIndex + 1);
          fetchData(skipIndex).then(({ data }) => {
            setItems(items.concat(data.annunciFunebri));
            if (data.annunciFunebri.length === 0) {
              setHasMore(false);
            }
          });
        }}
        hasMore={props.orgId ? false : hasMore}
        loader={<LinearProgress />}
      >
        <div className="giotto-grid-container">
          {items.map((attachment, index) => {
            const url = `/clients/${attachment.org_pointer.objectId}`;
            const photo =
              attachment.foto === undefined
                ? LogoAnnunciFunebri
                : attachment.foto.url;
            return (
              <AnnuncioCard
                key={index}
                objectId={attachment.objectId}
                createdAt={attachment.createdAt}
                isProd={isProd}
                org={attachment.org_pointer}
                title={""}
                url={url}
                photoUrl={photo}
                annuncioUrl={attachment.link_annuncifunebri}
              />
            );
          })}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default GiottoAnnunciFunebri;
