export const months = [
  "Gen",
  "Feb",
  "Mar",
  "Apr",
  "Mag",
  "Giu",
  "Lug",
  "Ago",
  "Set",
  "Ott",
  "Nov",
  "Dic",
];

export const decodeStr = (str) => {
  const s = "<b>" + str + "</b>";
  let e = document.createElement("decodeIt");
  e.innerHTML = s;
  return e.innerText;
}