import React, { useState } from "react";
import { Button, Tag, Divider, Table, Statistic, Card, Row, Col } from "antd";
import { useAuth0 } from "../../react-auth0-spa";
import format from "date-fns/format";
import { PlusOutlined } from "@ant-design/icons";
import RemovebgModal from "./RemovebgModal";
import config from "../../api_config.json";
import { useAppContext } from "../../context";
import ReactCompareImage from "react-compare-image";

const TabRemovebgOrg = (props) => {
  const [removebgModalVisible, setRemovebgModalVisible] = useState(false);
  const [removebgModalResult, setRemovebgModalResult] = useState();
  const {
    removebgCredits = { freeCreditsAmount: 0, paidCreditsAmount: 0 },
    removebgOrders = [],
  } = props;
  const { getTokenSilently } = useAuth0();
  const { isProd } = useAppContext();

  const createRemovebgOrder = async (values) => {
    try {
      const token = await getTokenSilently();

      const payload = {
        ...values,
        orgId: props.orgId,
      };
      const response = await fetch(
        `${config.removebg[isProd ? "prod" : "dev"]}/synthetic-transaction`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      );

      const responseData = await response.json();

      return responseData;
    } catch (error) {
      console.error(error);
    }
  };

  const onResultAcked = () => {
    setRemovebgModalVisible(false);
    setRemovebgModalResult("");
  };

  const onCreate = (values) => {
    createRemovebgOrder(values)
      .then((response) => {
        setRemovebgModalResult("OK");
      })
      .catch((error) => {
        console.error(error);
        setRemovebgModalResult("ERROR");
      });
  };

  const columns = [
    {
      title: "Data",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (data) => format(new Date(data), "dd/MM/yyyy"),
    },
    {
      title: "Ora",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (data) => format(new Date(data), "HH:mm"),
    },
    {
      title: "Crediti Acquistati",
      dataIndex: "paidCreditsAmount",
      key: "paidCreditsAmount",
    },
    {
      title: "Crediti Gratuiti",
      dataIndex: "freeCreditsAmount",
      key: "freeCreditsAmount",
    },
    { title: "Note", dataIndex: "note", key: "note" },
    {
      title: "Costo",
      dataIndex: "isPaid",
      key: "isPaid",
      render: (isPaid) => {
        return (
          <Tag color={isPaid ? "green" : "blue"}>
            {isPaid ? "PAID" : "FREE"}
          </Tag>
        );
      },
    },
    {
      title: "ID Pagamento",
      dataIndex: "paymentReference",
      key: "paymentReference",
    },
    {
      title: "Stato",
      dataIndex: "transactionStatus",
      key: "transactionStatus",
      render: (transactionStatus) => {
        const colors = {
          approved: "green",
          cancelled: "volcano",
          pending: "free",
        };

        return (
          <Tag color={colors[transactionStatus]}>
            {transactionStatus.toUpperCase()}
          </Tag>
        );
      },
    },
  ];

  return (
    <div>
      <Row gutter={9}>
        <Col span={3}>
          <Card>
            <Statistic
              title="Gratuiti"
              value={removebgCredits.freeCreditsAmount}
              precision={0}
              valueStyle={{
                color:
                  removebgCredits.freeCreditsAmount > 0 ? "#3f8600" : "#cf1322",
              }}
              suffix="crediti"
            />
          </Card>
        </Col>
        <Col span={3}>
          <Card>
            <Statistic
              title="Acquistati"
              value={removebgCredits.paidCreditsAmount}
              precision={0}
              valueStyle={{
                color:
                  removebgCredits.paidCreditsAmount > 0 ? "#3f8600" : "#cf1322",
              }}
              suffix="crediti"
            />
          </Card>
        </Col>
        <Col>
          <Card style={{ height: "100%" }}>
            <Button
              style={{ marginTop: "15px" }}
              type="primary"
              size="large"
              onClick={() => setRemovebgModalVisible(true)}
            >
              Crea Ordine <PlusOutlined />
            </Button>
            <RemovebgModal
              onResultAcked={onResultAcked}
              removebgModalResult={removebgModalResult}
              visible={removebgModalVisible}
              onCreate={onCreate}
              onCancel={() => setRemovebgModalVisible(false)}
            />
          </Card>
        </Col>
      </Row>
      <Divider />
      <Table dataSource={removebgOrders} columns={columns} />
      <Divider />
      <div className="giotto-grid-container">
        {props.allegatiRemovebg.map((allegato) => {
          if (!allegato.removebgRawImage) {
            return "";
          }

          return (
            <div className="giotto-grid-item">
              <Card>
                <div style={{  maxWidth: "25vw" }}>
                  <ReactCompareImage
                    leftImage={allegato.allegato.url}
                    rightImage={allegato.removebgRawImage}
                  />
                </div>
              </Card>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TabRemovebgOrg;
