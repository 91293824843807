import React, { useState, useEffect } from "react";
import { ProjectBuild } from "..";
import LinearProgress from '@material-ui/core/LinearProgress';
import { useAuth0 } from "../../react-auth0-spa";
import config from "../../api_config.json";
import { useAppContext } from "../../context";
import "./StatusGrid.css";
import _ from 'lodash';

const StatusGrid = () => {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const { isProd } = useAppContext();

  const { getTokenSilently } = useAuth0();

  useEffect(() => {
    const getLatestCodeBuildData = async () => {
      try {
        const token = await getTokenSilently();

        const response = await fetch(
          `${config.ci[isProd ? "prod" : "dev"]}/getSystemStatus`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );

        const responseData = await response.json();

        return responseData;
      } catch (error) {
        console.error(error);
      }
    };

    // const refresh = async () => {
    //   try {
    //     const token = await getTokenSilently();

    //     const response = await fetch(
    //       `${config.ci[isProd ? "prod" : "dev"]}/updateSystemStatus`,
    //       {
    //         method: "POST",
    //         headers: {
    //           Authorization: `Bearer ${token}`
    //         }
    //       }
    //     );

    //     await response.json();
    //   } catch (error) {
    //     console.error(error);
    //   }
    // };

    let abort = false;

    const refreshMe = async withoutRefresh => {
      setLoading(true);
      // if (!withoutRefresh) {
      //   await refresh();
      // }

      let newData = await getLatestCodeBuildData();
      if (!abort) {
        const sortedData = _.sortBy(newData, 'endTime').reverse();
        setItems(sortedData);
      }
      setLoading(false);
    };

    let timeoutId;

    function scheduled(withoutRefresh) {
      refreshMe(withoutRefresh);
      timeoutId = setTimeout(scheduled, 15000);
    }

    scheduled(true);

    return () => {
      abort = true;
      clearTimeout(timeoutId);
    };
  }, [getTokenSilently, isProd]);

  return (
    <div>
      <div>{loading && <LinearProgress />}</div>
      <div className="Grid">
        {items.map((props, key) => (
          <ProjectBuild key={`ProjectBuild-${key}-${isProd}`} {...props} />
          ))}
      </div>
    </div>
  );
};

export default React.memo(StatusGrid);
