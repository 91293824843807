import React, { useState } from "react";
import { Form, Modal, Result, Spin } from "antd";
import RemovebgForm from "./RemovebgForm";

const ModalResult = ({removebgModalResult}) => {
  if (removebgModalResult === "OK") {
    return (
      <Result
        status="success"
        title="Ordine creato con successo"
        subTitle="In pochi secondi l'ordine verrà processato"
      />
    );
  } else {
    return (
      <Result
        status="error"
        title="Qualcosa è andato storto"
        subTitle="L'ordine non è stato creato"
      />
    );
  }
};

const RemovebgModal = (props) => {
  const [form] = Form.useForm();
  const [loading, setIsLoading] = useState(false);

  if (props.removebgModalResult) {
    return (
      <Modal
        visible={props.visible}
        title="Crea un ordine di crediti Removebg gratuito"
        cancelText=""
        onCancel={() => {
          setIsLoading(false);
          props.onResultAcked();
        }}
        onOk={() => {
          setIsLoading(false);
          props.onResultAcked();
        }}
      >
        <ModalResult removebgModalResult={props.removebgModalResult} />
      </Modal>
    );
  }

  return (
    <Modal
      visible={props.visible}
      title="Crea un ordine di crediti Removebg gratuito"
      okText="Crea Ordine"
      cancelText="Annulla"
      onCancel={() => {
        setIsLoading(false);
        props.onCancel();
      }}
      onOk={() => {
        setIsLoading(true);
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            props.onCreate(values);
          })
          .catch((info) => {
            console.error("Validate Failed:", info);
          });
      }}
    >
      {!loading && <RemovebgForm form={form} />}
      {loading && <Spin size="large" />}
    </Modal>
  );
};

export default RemovebgModal;
