import React, { useState, useEffect } from "react";
import {
  Alert,
  Badge,
  Breadcrumb,
  Button,
  Col,
  DatePicker,
  Divider,
  Modal,
  Result,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Tag,
  Timeline,
  Tooltip,
  Typography,
} from "antd";
import formatDistance from "date-fns/formatDistance";
import { it } from "date-fns/locale";
import "moment/locale/it";
import { useAuth0 } from "../../react-auth0-spa";
import config from "../../api_config.json";
import { useAppContext } from "../../context";
import fromUnixTime from "date-fns/fromUnixTime";
import format from "date-fns/format";
import subHours from "date-fns/subHours";
import differenceInHours from "date-fns/differenceInHours";
import ReactJson from "react-json-view";
import {
  CloseCircleOutlined,
  SearchOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  useQueryParams,
  useQueryParam,
  BooleanParam,
  StringParam,
  withDefault,
} from "use-query-params";

const { Option } = Select;
const { Title, Text } = Typography;

const appColors = {
  ofc: "purple",
  eterno: "purple",
  giotto: "geekblue",
};

const actionColors = {
  startup: "lime",
  removebg: "lime",
  login: "lime",
  create: "green",
  update: "cyan",
  delete: "orange",
};

const now = new Date();

const makeTitle = (item) => {
  return (
    <>
      {item.action && (
        <Tag color={item.error ? "volcano" : actionColors[item.action]}>
          {item.action.toUpperCase()}
          {item.objectClass ? `: ${item.objectClass}` : ""}
        </Tag>
      )}
      {item.msg && (
        <Text
          style={{ marginRight: "10px" }}
          type={item.error ? "danger" : "secondary"}
        >
          {item.msg}
        </Text>
      )}
      {item.timestamp && (
        <Tooltip
          title={
            item.timestamp
              ? format(fromUnixTime(item.timestamp), "dd/MM/yyyy - HH:mm") +
                " UTC"
              : ""
          }
        >
          <Tag>
            {formatDistance(fromUnixTime(item.timestamp), now, {
              locale: it,
            })}
          </Tag>
        </Tooltip>
      )}
    </>
  );
};

const SystemEvents = () => {
  const [loading, setLoading] = useState(true);
  const [onlyErrors, setOnlyErrors] = useQueryParam("onlyErrors", BooleanParam);
  const [availableActions, setAvailableActions] = useState([]);
  const [availableApplications, setAvailableApplications] = useState([]);
  const [onlyActions, setOnlyActions] = useState(false);
  const [action, setAction] = useQueryParam("action");
  const [pageSize, setPageSize] = useState(100);
  const [application, setApplication] = useQueryParam("application");
  const [timeQuery, setTimeQuery] = useQueryParams({
    from: withDefault(StringParam, new Date().toISOString()),
    to: withDefault(StringParam, new Date().toISOString()),
  });
  const [items, setItems] = useState([]);
  const { isProd } = useAppContext();
  const { getTokenSilently } = useAuth0();

  const [isModalVisible, setIsModalVisible] = useState([]);

  const showModal = (index) => () => {
    isModalVisible[index] = true;
    setIsModalVisible([...isModalVisible]);
  };

  const handleCancel = (index) => () => {
    isModalVisible[index] = false;
    setIsModalVisible([...isModalVisible]);
  };

  const detectAvailableActions = (data) => {
    const availableActionsRaw = data.reduce((acc, item) => {
      if (item.metricType && item.action) {
        if (!acc[item.action]) {
          acc[item.action] = 0;
        }
        acc[item.action] = acc[item.action] = +1;
      }
      return acc;
    }, {});

    const availableActions = Object.keys(availableActionsRaw);

    setAvailableActions(availableActions);
  };

  const detectAvailableApplications = (data) => {
    const availableApplicationsRaw = data.reduce((acc, item) => {
      if (item.application) {
        if (!acc[item.application]) {
          acc[item.application] = 0;
        }
        acc[item.application] = acc[item.application] = +1;
      }
      return acc;
    }, {});

    const availableApplications = Object.keys(availableApplicationsRaw);

    setAvailableApplications(availableApplications);
  };

  const refreshData = () => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const token = await getTokenSilently();

        const params = {
          from: timeQuery.from,
          to: timeQuery.to,
        };

        if (application) {
          params.application = application;
        }

        if (action) {
          params.action = action;
        }

        if (pageSize) {
          params.pageSize = pageSize;
        }

        if (onlyErrors) {
          params.onlyErrors = true;
        }

        if (onlyActions) {
          params.onlyActions = true;
        }

        const response = await fetch(
          `${config.baseUrl[isProd ? "prod" : "dev"]}/events?` +
            new URLSearchParams(params),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const responseData = await response.json();

        return responseData;
      } catch (error) {
        console.error(error);
      }
    };

    fetchData().then((data = []) => {
      setItems(data);
      setLoading(false);
      detectAvailableActions(data);
      detectAvailableApplications(data);
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const token = await getTokenSilently();

        const params = {
          from: timeQuery.from,
          to: timeQuery.to,
        };

        if (application) {
          params.application = application;
        }

        if (action) {
          params.action = action;
        }

        if (pageSize) {
          params.pageSize = pageSize;
        }

        if (onlyErrors) {
          params.onlyErrors = true;
        }

        if (onlyActions) {
          params.onlyActions = true;
        }

        const response = await fetch(
          `${config.baseUrl[isProd ? "prod" : "dev"]}/events?` +
            new URLSearchParams(params),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const responseData = await response.json();

        return responseData;
      } catch (error) {
        console.error(error);
      }
    };

    fetchData().then((data = []) => {
      setItems(data);
      setLoading(false);
      detectAvailableActions(data);
      detectAvailableApplications(data);
    });
  }, [
    getTokenSilently,
    isProd,
    onlyErrors,
    onlyActions,
    timeQuery,
    action,
    application,
    pageSize,
  ]);

  const onCopy = (copy) => {
    const container = document.createElement("textarea");
    const val = copy.src;

    container.innerHTML =
      typeof val === "string" ? val : JSON.stringify(val, null, "  ");

    document.body.appendChild(container);
    container.select();
    // copy the same quote-less value back to the clipboard
    document.execCommand("copy");
    document.body.removeChild(container);
  };

  const filterTimePeriod = differenceInHours(
    new Date(timeQuery.to),
    new Date(timeQuery.from)
  );

  const resetFilters = () => {
    setAction(undefined);
    setOnlyActions(false);
    setOnlyErrors(true);
    setApplication();
    setPageSize(100);
    setTimeQuery({
      from: new Date(subHours(new Date(), 1)).toISOString(),
      to: new Date().toISOString(),
    });
  };

  return (
    <div style={{ margin: "16px 16px" }}>
      <Breadcrumb style={{ marginBottom: "20px" }}>
        <Breadcrumb.Item>System Events</Breadcrumb.Item>
        <Breadcrumb.Item>All</Breadcrumb.Item>
      </Breadcrumb>
      <Divider />
      <Title level={3} style={{ marginBottom: "20px" }}>
        <Space>
          System Events
          <Badge overflowCount={5000} count={items.length}></Badge>
        </Space>
      </Title>
      <Divider />
      <Title level={5}>Filtri Rapidi</Title>
      <Space>
        <Button
          onClick={() => {
            resetFilters();
            setAction("removebg");
            setOnlyErrors(false);
            setTimeQuery({
              from: new Date(subHours(new Date(), 24 * 30)).toISOString(),
              to: new Date().toISOString(),
            });
          }}
        >
          Removebg (30 giorni)
        </Button>
        <Button
          onClick={() => {
            resetFilters();
            setAction("requestImageEnhancement");
            setOnlyErrors(false);
            setTimeQuery({
              from: new Date(subHours(new Date(), 24 * 30)).toISOString(),
              to: new Date().toISOString(),
            });
          }}
        >
          Migliora Foto (30 giorni)
        </Button>
        <Button
          onClick={() => {
            resetFilters();
            setAction("requestVideo");
            setOnlyErrors(false);
            setTimeQuery({
              from: new Date(subHours(new Date(), 24 * 30)).toISOString(),
              to: new Date().toISOString(),
            });
          }}
        >
          Video (30 giorni)
        </Button>
        <Button
          onClick={() => {
            resetFilters();
            setOnlyErrors(true);
            setTimeQuery({
              from: new Date(subHours(new Date(), 24)).toISOString(),
              to: new Date().toISOString(),
            });
          }}
        >
          Errori (24 ore)
        </Button>
        <Button
          onClick={() => {
            resetFilters();
          }}
        >
          Reset Filters
        </Button>
      </Space>
      <Divider />

      <Row style={{ margin: "20px 0 20px 0" }}>
        <Col>
          <Text strong>Da</Text>
          <DatePicker
            defaultValue={moment(new Date(timeQuery.from))}
            bordered={false}
            showTime
            onChange={(_, dateString) =>
              setTimeQuery({ from: new Date(dateString).toISOString() })
            }
          />
        </Col>
        <Col style={{ marginLeft: "20px" }}>
          <Text strong>A</Text>
          <DatePicker
            defaultValue={moment(new Date(timeQuery.to))}
            bordered={false}
            showTime
            onChange={(_, dateString) =>
              setTimeQuery({ to: new Date(dateString).toISOString() })
            }
          />
        </Col>
        {filterTimePeriod > 24 && (action === "" || application === "") && (
          <Col style={{ marginLeft: "20px" }}>
            <Alert
              message="Il periodo temporale selezionato è troppo ampio, alcuni eventi non verranno"
              type="warning"
              showIcon
              closable
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          <Text strong>Filtri</Text>
          <div style={{ marginTop: "20px" }}>
            <Space>
              <Text>Solo Errori:</Text>
              <Switch
                checked={onlyErrors}
                onChange={() => setOnlyErrors(!onlyErrors)}
              />
            </Space>
          </div>
          <div style={{ marginTop: "20px" }}>
            <Space>
              <Text>Solo Azioni:</Text>
              <Switch
                checked={onlyActions}
                onChange={() => setOnlyActions(!onlyActions)}
              />
            </Space>
          </div>
          <div style={{ marginTop: "20px" }}>
            <Space>
              <Text>Tipo di azione:</Text>
              <Select
                defaultValue={action}
                disabled={availableActions.length === 0}
                style={{ width: 120 }}
                onChange={setAction}
              >
                {availableActions.map((availableAction) => {
                  return (
                    <Option key={availableAction} value={availableAction}>
                      {availableAction.toUpperCase()}
                    </Option>
                  );
                })}
              </Select>
            </Space>
          </div>
          <div style={{ marginTop: "20px" }}>
            <Space>
              <Text>Applicazione:</Text>
              <Select
                defaultValue={application}
                disabled={availableApplications.length === 0}
                style={{ width: 120 }}
                onChange={setApplication}
              >
                {availableApplications.map((availableApplication) => {
                  return (
                    <Option
                      key={availableApplication}
                      value={availableApplication}
                    >
                      {availableApplication.toUpperCase()}
                    </Option>
                  );
                })}
              </Select>
            </Space>
          </div>
          <div style={{ marginTop: "20px" }}>
            <Space>
              <Text>Max events:</Text>
              <Select
                value={pageSize}
                style={{ width: 120 }}
                onChange={setPageSize}
              >
                <Option value={25}>25</Option>
                <Option value={50}>50</Option>
                <Option value={100}>100</Option>
                <Option value={250}>250</Option>
                <Option value={1000}>1000</Option>
              </Select>
            </Space>
          </div>
          <div style={{ marginTop: "20px" }}>
            <Button onClick={refreshData}>Refesh Data</Button>
          </div>
        </Col>
        <Col flex="auto">
          {loading && (
            <div style={{ textAlign: "center" }}>
              <Spin size="large" />
            </div>
          )}
          {!loading && items.length === 0 && (
            <Result
              status="404"
              title="Non ci sono eventi per il filtro selezionato"
              extra={
                <Button type="primary" key="console" onClick={refreshData}>
                  Riprova
                </Button>
              }
            />
          )}
          {!loading && (
            <Timeline mode="left">
              {items.map((item, index) => {
                if (Object.keys(item).length === 0) {
                  return "";
                }

                if (`${item.timestamp}`.length === 13) {
                  item.timestamp = Math.floor(item.timestamp / 1000);
                }

                let icon;

                if (item.error) {
                  icon = <CloseCircleOutlined />;
                }

                if (item.action) {
                  icon = <UserSwitchOutlined />;
                }

                isModalVisible.push(false);

                const application = item.application || "System Event";

                return (
                  <Timeline.Item
                    key={index}
                    color={item.error ? "red" : actionColors[item.action]}
                    dot={icon}
                    label={
                      <Badge dot={item.error} offset={[0, 10]}>
                        <Tag color={appColors[application]}>
                          {application.toUpperCase()}
                        </Tag>
                      </Badge>
                    }
                  >
                    {makeTitle(item)}
                    <Button
                      onClick={showModal(index)}
                      style={{ marginLeft: "10px" }}
                      size="small"
                      shape="circle"
                      icon={<SearchOutlined />}
                    />
                    <Modal
                      width={"50%"}
                      title="Event Details"
                      visible={isModalVisible[index]}
                      footer={null}
                      onCancel={handleCancel(index)}
                    >
                      <ReactJson
                        src={item}
                        name={null}
                        enableClipboard={onCopy}
                      />
                      <Divider />
                      <Space>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`https://eu-central-1.console.aws.amazon.com/cloudwatch/home?region=eu-central-1#logsV2:log-groups/log-group/${encodeURIComponent(
                            item.log_group
                          )}/log-events/${encodeURIComponent(item.log_stream)}`}
                        >
                          <Button>Open in CloudWatch</Button>
                        </a>
                        {item.orgId && (
                          <Link to={`/clients/${item.orgId}`}>
                            <Button>Apri Profilo Organizzazione</Button>
                          </Link>
                        )}
                      </Space>
                    </Modal>
                  </Timeline.Item>
                );
              })}
            </Timeline>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default SystemEvents;
