import React, { useState, useEffect, useCallback } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { LazyLoadImage } from "react-lazy-load-image-component";
import _ from "lodash";
import {
  Badge,
  Breadcrumb,
  Card,
  Carousel,
  Divider,
  Space,
  Tag,
  Typography,
} from "antd";
import { useAuth0 } from "../../react-auth0-spa";
import config from "../../api_config.json";
import { useAppContext } from "../../context";
import formatDistance from "date-fns/formatDistance";
import { it } from "date-fns/locale";
import InfiniteScroll from "react-infinite-scroll-component";

const { Title } = Typography;

const GiottoPreview = ({ title, url, pages = [], updatedAt, status }) => {
  return (
    <div className="giotto-grid-item">
      <Card
        title={title}
        extra={
          <a href={url} target="_blank" rel="noopener noreferrer">
            Org
          </a>
        }
        style={{ width: "25vw" }}
      >
        <Carousel autoplay>
          {pages.map((page) => {
            if (!page || !page.preview || !page.preview.url) {
              return "";
            }

            return (
              <div>
                <LazyLoadImage
                  width="100%"
                  src={page.preview.url}
                  alt={page.objectId}
                />
              </div>
            );
          })}
        </Carousel>
        <Tag style={{ marginTop: "10px" }}>
          {formatDistance(new Date(updatedAt), new Date(), { locale: it })}
        </Tag>
        <Tag
          style={{ marginTop: "10px" }}
          color={status === "hidden" ? "volcano" : "green"}
        >
          {status === "hidden" ? "ELIMINATO" : "ATTIVO"}
        </Tag>
      </Card>
    </div>
  );
};

const GiottoClientProjects = () => {
  const [items, setItems] = useState([]);
  const [skipIndex, setSkipIndex] = useState(1);
  const { isProd } = useAppContext();
  const { getTokenSilently } = useAuth0();

  const fetchData = useCallback(
    async (skip = 0) => {
      const limit = 12;

      try {
        const token = await getTokenSilently();

        const response = await fetch(
          `${
            config.baseUrl[isProd ? "prod" : "dev"]
          }/giotto/projects?limit=${limit}&skip=${skip * limit}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const responseData = await response.json();
        return responseData;
      } catch (error) {
        console.error(error);
      }
    },
    [isProd, getTokenSilently]
  );

  useEffect(() => {
    fetchData(0).then(({ data }) => {
      setItems(data.giottoClientProjects);
    });
  }, [fetchData, setItems]);

  return (
    <div style={{ margin: "16px 16px" }}>
      <Breadcrumb style={{ marginBottom: "20px" }}>
        <Breadcrumb.Item>Giotto</Breadcrumb.Item>
        <Breadcrumb.Item>Progetti dei Clienti</Breadcrumb.Item>
      </Breadcrumb>
      <Divider />
      <Title level={3} style={{ marginBottom: "40px" }}>
        <Space>
          Giotto - Progetti dei Clienti
          <Badge overflowCount={5000} count={items.length}></Badge>
        </Space>
      </Title>
      <Divider />
      <InfiniteScroll
        dataLength={items.length}
        next={() => {
          setSkipIndex(skipIndex + 1);
          fetchData(skipIndex).then(({ data }) => {
            setItems(items.concat(data.giottoClientProjects));
          });
        }}
        hasMore={true}
        loader={<LinearProgress />}
      >
        <div className="giotto-grid-container">
          {items.map((editorCanvas, index) => {
            const title = `${_.get(
              editorCanvas,
              "metadata.projectTypeName",
              ""
            )} ${_.get(editorCanvas, "metadata.formatName", "")} ${_.get(
              editorCanvas,
              "metadata.templatesCategoryName",
              ""
            )}`;

            const url = `/clients/${editorCanvas.org_pointer.objectId}`;

            return (
              <GiottoPreview
                key={index}
                url={url}
                title={title}
                updatedAt={editorCanvas.updatedAt}
                status={editorCanvas.status}
                pages={editorCanvas.editor_canvas_pages}
              />
            );
          })}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default GiottoClientProjects;
