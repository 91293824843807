import React from "react";
import GiottoAssetOrganiser from './GiottoAssetOrganiser';

const GiottoImages = () => {
  return (
    <GiottoAssetOrganiser collection="editor_images_default" fileColName="file" />
  );
};

export default GiottoImages;
