import React from "react";
import { Form, Input } from 'antd';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const ArubaCredentialsForm = (props) => {
  return (
    <Form
      {...layout}
      form={props.form}
      initialValues={{ freeCreditsAmount: 0, paidCreditsAmount: 0 }}
      onFinish={props.onFinish}
    >
      <Form.Item
        label="Aruba Username"
        name="arubaUsername"
        rules={[{ required: true, message: 'Inserire un username' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Aruba Password"
        name="arubaPassword"
        rules={[{ required: true, message: 'Inserire una password' }]}
      >
        <Input />
      </Form.Item>
    </Form>
  );
};

export default ArubaCredentialsForm;
