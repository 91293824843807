import React from "react";
import { Switch } from "antd";
import "moment/locale/it";
import { useAuth0 } from "../../react-auth0-spa";
import config from "../../api_config.json";
import { useAppContext } from "../../context";
import updateOrgDetails from "./updateOrgDetails";

const FieldSwitch = (props) => {
  const { org, property } = props;
  const { getTokenSilently } = useAuth0();
  const { isProd } = useAppContext();

  const handleChange = (value) => {
    const fields = [
      {
        key: property,
        value,
      },
    ];

    updateOrgDetails(getTokenSilently, config, isProd, org.objectId, fields);
  };

  return <Switch onChange={handleChange} defaultChecked={org[property]} />;
};

export default FieldSwitch;
