import React, { useState } from "react";
import { Form, Modal, Result, Spin } from "antd";
import ArubaCredentialsForm from "./ArubaCredentialsForm";

const ModalResult = ({arubaModalResult}) => {
  if (arubaModalResult === "OK") {
    return (
      <Result
        status="success"
        title="Credenziali salvatecon successo"
      />
    );
  } else {
    return (
      <Result
        status="error"
        title="Qualcosa è andato storto"
      />
    );
  }
};

const ArubaCrdentialsModal = (props) => {
  const [form] = Form.useForm();
  const [loading, setIsLoading] = useState(false);

  if (props.arubaModalResult) {
    return (
      <Modal
        visible={props.visible}
        title="Crea un ordine di crediti Removebg gratuito"
        cancelText=""
        onCancel={() => {
          setIsLoading(false);
          props.onResultAcked();
        }}
        onOk={() => {
          setIsLoading(false);
          props.onResultAcked();
        }}
      >
        <ModalResult arubaModalResult={props.arubaModalResult} />
      </Modal>
    );
  }

  return (
    <Modal
      visible={props.visible}
      title="Imposta le credenziali Aruba per questa org"
      okText="Imposta nuove credenziali"
      cancelText="Annulla"
      onCancel={() => {
        setIsLoading(false);
        props.onCancel();
      }}
      onOk={() => {
        setIsLoading(true);
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            props.onCreate(values);
          })
          .catch((info) => {
            console.error("Validate Failed:", info);
          });
      }}
    >
      {!loading && <ArubaCredentialsForm form={form} />}
      {loading && <Spin size="large" />}
    </Modal>
  );
};

export default ArubaCrdentialsModal;
