import React, { useState } from "react";
import { Card, Row, Col, Divider, Switch } from "antd";
import { Chart } from "chart.js";
import { Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { months } from "../../utils";

Chart.register(ChartDataLabels);

const { Meta } = Card;

const colors = {
  2021: "red",
  2020: "blue",
  2019: "brown",
  2018: "green",
  2017: "pink",
};
const calcChartDataCalendarYear = (org) => {
  return {
    labels: months,
    datasets: org.invoices_by_cal_years.map((year) => {
      return {
        data: year.months.map((month) => {
          return month.total;
        }),
        label: year.name,
        fill: false,
        backgroundColor: colors[year.name],
        borderColor: colors[year.name],
        // datalabels: {
        //   color: "#fff",
        // },
      };
    }),
    // [
    //   {
    //     label: "# di Fatture",
    //     data: org.invoices_by_cal_years[tot - 1].months.reduce(
    //       (acc, period) => {
    //         acc.push(period.total);
    //         return acc;
    //       },
    //       []
    //     ),
    //     fill: false,
    //     backgroundColor: "#165C7D",
    //     borderColor: "#165C7D",
    //     datalabels: {
    //       color: "#fff",
    //     },
    //   },
    // ],
  };
};
const calcFatt = (org) => {
  return {
    labels: months,
    datasets: org.invoices_by_cal_years.map((year) => {
      return {
        data: year.months.map((month) => {
          return month.revenue;
        }),
        label: year.name,
        fill: false,
        backgroundColor: colors[year.name],
        borderColor: colors[year.name],
        // datalabels: {
        //   color: "#fff",
        // },
      };
    }),
    // [
    //   {
    //     label: "# di Fatture",
    //     data: org.invoices_by_cal_years[tot - 1].months.reduce(
    //       (acc, period) => {
    //         acc.push(period.total);
    //         return acc;
    //       },
    //       []
    //     ),
    //     fill: false,
    //     backgroundColor: "#165C7D",
    //     borderColor: "#165C7D",
    //     datalabels: {
    //       color: "#fff",
    //     },
    //   },
    // ],
  };
};

const TabFatture = (props) => {
  const { org } = props;
  const [showNumbersChart1, setShowNumbersChart1] = useState(false);
  const [showNumbersChart2, setShowNumbersChart2] = useState(false);

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {},
  };

  const options2 = {
    ...options,
  };

  if (showNumbersChart1) {
    options.plugins.datalabels = {
      backgroundColor: function (context) {
        return context.dataset.backgroundColor;
      },
      borderRadius: 4,
      color: "white",
      font: {
        weight: "bold",
      },
      formatter: Math.round,
      padding: 6,
    };
  }
  if (showNumbersChart2) {
    options2.plugins.datalabels = {
      backgroundColor: function (context) {
        return context.dataset.backgroundColor;
      },
      borderRadius: 4,
      color: "white",
      font: {
        weight: "bold",
      },
      formatter: Math.round,
      padding: 6,
    };
  }

  return (
    <>
      <Row>
        <Col span={12}>
          {/* <Badge
            overflowCount={1000}
            count={calcChartDataCalendarYear(org).datasets[0].data.reduce(
              (acc, value) => {
                acc += value;
                return acc;
              },
              0
            )}
          > */}
          {/* <h2>Totale Fatture al mese&nbsp;&nbsp;&nbsp;</h2> */}
          {/* </Badge> */}
          <Card title="Numero di Fatture / Mese" bordered={true}>
            <Line
              height="150px"
              data={calcChartDataCalendarYear(org)}
              options={options}
            />
            <Meta
              description={
                <>
                  {/* <Space direction="vertical"> */}
                  <Divider dashed />
                  <Row>
                    <Col span={4}>Mostra valori</Col>
                    <Col span={12}>
                      <Switch
                        defaultChecked={showNumbersChart1}
                        onChange={(checked) => setShowNumbersChart1(checked)}
                      />
                    </Col>
                  </Row>
                  {/* </Space> */}
                </>
              }
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Fatturato" bordered={true}>
            <Line height="150px" data={calcFatt(org)} options={options2} />
            <Meta
              description={
                <>
                  {/* <Space direction="vertical"> */}
                  <Divider dashed />
                  <Row>
                    <Col span={4}>Mostra valori</Col>
                    <Col span={12}>
                      <Switch
                        defaultChecked={showNumbersChart2}
                        onChange={(checked) => setShowNumbersChart2(checked)}
                      />
                    </Col>
                  </Row>
                  {/* </Space> */}
                </>
              }
            />
          </Card>

          {/* <Badge
            overflowCount={1000}
            count={calcFatt(org).datasets[0].data.reduce((acc, value) => {
              acc += value;
              return acc;
            }, 0)}
          > */}
          {/* <h2>Totale Fatture al mese&nbsp;&nbsp;&nbsp;</h2> */}
          {/* </Badge> */}
        </Col>
      </Row>
    </>
  );
};

export default TabFatture;
