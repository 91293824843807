import React from "react";
import GiottoAssetOrganiser from './GiottoAssetOrganiser';

const GiottoSymbols = () => {
  return (
    <GiottoAssetOrganiser collection="editor_symbols_default" fileColName="img" />
  );
};

export default GiottoSymbols;
