import React from "react";
import { Card, Collapse } from "antd";
import _ from "lodash";
import { ofcUrl } from "../../api_config.json";
import { useAppContext } from "../../context";

const { Panel } = Collapse;
const TabOrgDocs = (props) => {
  const { docs } = props;
  const { isProd } = useAppContext();

  const sortedDocs = _.orderBy(docs, ["nome_documento"], ["asc"]);

  return (
    <Collapse>
      {sortedDocs.map((doc, index) => {
        let title = doc.nome_documento || "TITOLO MANCANTE";
        if (doc.precompilato === false) {
          title = `${title} [Precompilato]`;
        }
        if (doc.generico) {
          title = `${title} [Generico]`;
        }
        return (
          <Panel header={title} key={index}>
            <div className="site-card-border-less-wrapper">
              <Card
                bordered={false}
                style={{ width: "80%" }}
                extra={
                  <div>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`${
                        ofcUrl[isProd ? "prod" : "dev"]
                      }/index.php?page=modificaTemplateDocumento&documentId=${
                        doc.objectId
                      }`}
                    >
                      Modifica su Eterno
                    </a>
                  </div>
                }
              >
                <div dangerouslySetInnerHTML={{ __html: doc.documento }} />
              </Card>
            </div>
          </Panel>
        );
      })}
    </Collapse>
  );
};

export default TabOrgDocs;
