import React from "react";
import "./AppsHub.css";
import { useAuth0 } from "../../react-auth0-spa";
import { appsConfig } from "../../config";
import { useAppContext } from "../../context";

function CategoryApps(props) {
  return (
    <div className="apps">
      {props.apps.map((app) => {
        if (app.env && app.env !== props.env) {
          return <div key={app.name + app.env}></div>;
        }

        return (
          <a
            key={app.name}
            className="app"
            href={app.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img alt="logo" className="app__cover" src={app.logo} />
            <div className="app__label" style={{ background: props.color }}>
              {app.name}
            </div>
          </a>
        );
      })}
    </div>
  );
}

function Apps(props) {
  return props.data.map((category) => {
    if (category.displayOnSide !== props.side) {
      return <div key={category.title}></div>;
    }

    const colors = {
      left: "#278A74",
      right: "#692dac",
      extra: "#2c8ebd",
    };

    return (
      <div key={category.title}>
        <h2 style={{ color: colors[props.side] }}>{category.title}</h2>
        <CategoryApps
          apps={category.apps}
          env={props.env}
          color={colors[props.side]}
        />
      </div>
    );
  });
}

function AppsHub() {
  const { isProd } = useAppContext();
  const env = isProd ? "prod" : "dev";
  const { isAuthenticated } = useAuth0();

  if (!isAuthenticated) {
    return <div></div>;
  }

  return (
    <div className="AppsHub">
      <div className="wrapper">
        <div className="container">
          <Apps data={appsConfig} env={env} side="left" />
        </div>

        <div className="container">
          <Apps data={appsConfig} env={env} side="right" />
        </div>

        <div className="container">
          <Apps data={appsConfig} env={env} side="extra" />
        </div>
      </div>
    </div>
  );
}

export default AppsHub;
