import React, {useState} from "react";
import { Input, Space, Button, Table, Switch, Tag, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import FeatureToggles from "../../feature_toggles.json";
import { useAuth0 } from "../../react-auth0-spa";
import config from "../../api_config.json";
import { useAppContext } from "../../context";

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

const TabOrgFeatureToggles = (props) => {
  const { getTokenSilently } = useAuth0();
  const { isProd } = useAppContext();
  const { orgId, featureToggles = [] } = props;
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const data = FeatureToggles.map((FeatureToggle) => {
    const orgFeatureToggle = featureToggles.find(
      (orgFeatureToggle) => orgFeatureToggle.key === FeatureToggle.key
    );

    const record = {
      key: FeatureToggle.key,
      description: FeatureToggle.description,
      enabled: orgFeatureToggle ? orgFeatureToggle.enabled : false,
      orgId,
    };

    if (orgFeatureToggle && orgFeatureToggle.objectId) {
      record.objectId = orgFeatureToggle.objectId;
    }

    return record;
  });

  const setOrgFeatureToggleValue = async (data) => {
    console.log(data);
    try {
      const token = await getTokenSilently();

      const response = await fetch(
        `${config.baseUrl[isProd ? "prod" : "dev"]}/feature-toggle`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        }
      );

      const responseData = await response.json();

      return responseData;
    } catch (error) {
      console.error(error);
    }
  };

  const onChange = (data) => (enabled) => {
    const payload = {
      ...data,
      enabled,
      orgId,
    };

    setOrgFeatureToggleValue(payload)
      .then(() => {
        message.success("Feature toggle aggiornata con successo");
      })
      .catch((error) => {
        message.error("Errore: Feature toggle non aggiornata");
      });
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            // setSearchInput(node);
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          // if (searchInput) {
          //   searchInput.select()
          // }
        }, 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Progetto",
      dataIndex: "key",
      key: "key",
      filters: [
        { text: "Giotto", value: "giotto" },
        { text: "Eterno", value: "eterno" },
      ],
      onFilter: (value, record) => record.key.startsWith(value),
      render: (key) => {
        const [project] = key.split(".");

        const colors = {
          gestionale: "purple",
          eterno: "purple",
          giotto: "geekblue",
        };

        return <Tag color={colors[project]}>{project.toUpperCase()}</Tag>;
      },
    },
    {
      title: "Value",
      key: "enabled",
      dataIndex: "enabled",
      render: (enabled, record) => {
        return <Switch defaultChecked={enabled} onChange={onChange(record)} />;
      },
    },
    {
      title: "Type",
      dataIndex: "key",
      key: "key",
      filters: data
        .map((item) => item.key)
        .map((value) => {
          const [, action] = value.split(".");
          return action;
        })
        .filter(onlyUnique)
        .sort()
        .map((value) => {
          return {
            text: value.toUpperCase(),
            value,
          };
        }),
      onFilter: (value, record) => record.key.includes(value),
      render: (key) => {
        const [, action] = key.split(".");
        const colors = {
          create: "gold",
          delete: "red",
          use: "blue",
          view: "green",
        };

        return <Tag color={colors[action]}>{action.toUpperCase()}</Tag>;
      },
    },
    {
      title: "Object",
      dataIndex: "key",
      key: "key",
      ...getColumnSearchProps("key"),
      render: (key) => {
        const [, , object] = key.split(".");
        return <Tag>{object?.toUpperCase()}</Tag>;
      },
    },
    {
      title: "Key",
      dataIndex: "key",
      key: "key",
      ...getColumnSearchProps("key"),
    },
    {
      title: "Descrizione",
      dataIndex: "description",
      key: "description",
      ...getColumnSearchProps("description"),
    },
  ];

  return (
    <Table dataSource={data} columns={columns} pagination={{ pageSize: 100 }} />
  );
};

export default TabOrgFeatureToggles;
