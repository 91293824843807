import React from 'react';
import { useAuth0 } from "../../react-auth0-spa";
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import LockIcon from '@material-ui/icons/Lock';

import './Login.css';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
}));

function Login() {
  const { loginWithRedirect } = useAuth0();
  const classes = useStyles();

  return (
    <div className="Login">
        {/* <Button
          onClick={() =>
            loginWithRedirect({})
          }
          color="secondary"
          className={classes.button}>
            Login
          </Button> */}

          <Fab onClick={() =>
            loginWithRedirect({})
          } variant="extended" size="large" color="secondary"  aria-label="like" className={classes.fab}>
          <LockIcon className={classes.extendedIcon} />
          Login
          </Fab>

    </div>
  );
}

export default Login;
