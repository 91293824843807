import React from "react";
import apiConfig from "../../api_config.json";
import { AppContext } from "../../context";
import {
  Breadcrumb,
  Button,
  Select,
  Space,
  Typography,
  Spin,
  Steps,
  Divider,
  Alert,
  Result,
} from "antd";
import {
  PieChartOutlined,
  DatabaseOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

const { Step } = Steps;

const { Title, Text } = Typography;
const { Option } = Select;

const SINGLE_COLLECTION = "Singola Collection";
const FULL_DB = "Intero Database";

class CreateBackup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 0,
      restoreType: "",
      data: [],
      loading: false,
      selectedKey: "",
      backupCreated: false,
      error: false,
    };
  }

  componentDidMount() {
    this.fetchCollections();
  }

  fetchCollections = () => {
    this.setState({ loading: true });
    const url = `${
      apiConfig.mongo[this.context.isProd ? "prod" : "dev"]
    }/collections`;

    fetch(url, {
      headers: {
        Authorization: `Bearer ${this.props.accessToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        response.json().then(({ data }) => {
          const collectionsData = data.collections.map((collectionName) => ({
            text: collectionName,
            value: collectionName,
          }));
          this.setState({
            loading: false,
            data: collectionsData,
          });
        });
      })
      .catch((error) => {
        this.setState({
          error,
        });
      });
  };

  createBackup = () => {
    this.setState({ loading: true });

    const url = `${
      apiConfig.mongo[this.context.isProd ? "prod" : "dev"]
    }/backup`;

    const payload = {};

    if (this.state.collection) {
      payload.collection = this.state.collection;
    }

    fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${this.props.accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        response.json().then(({ data }) => {
          this.setState({
            loading: false,
            backupCreated: true,
          });
        });
      })
      .catch((error) => {
        this.setState({
          error,
        });
      });
  };

  onCollectionChange = (value) => {
    this.setState({
      collection: value,
      step: 2,
    });
  };

  onBackupTypeChange = (value) => {
    this.setState({
      restoreType: value,
      step: value === FULL_DB ? 2 : 1,
    });
  };

  render() {
    const { data, loading } = this.state;

    if (this.state.error) {
      return (
        <Result
          status="error"
          title="Al momento non è possibile creare il backup"
          subTitle={`Qualcosa è andato storto: ${this.state.error}. Se hai aperto questo link diretto, passa prima dalla homepage di MC.`}
        />
      );
    }

    if (this.state.backupCreated) {
      return (
        <Result
          status="success"
          title="Backup lanciato con successo"
          subTitle="L'operazione pu&ograve; richiedere alcuni minuti per completare. Al termine il backup sar&agrave; disponibile nella dashboard di ripristino."
          extra={[
            <Link to="/db/backup/restore">
              <Button type="primary" key="console">
                Dashboard di Ripristino
              </Button>
            </Link>,
            <Button
              onClick={() => this.setState({ backupCreated: false })}
              key="buy"
            >
              Esegui un altro backup
            </Button>,
          ]}
        />
      );
    }

    return (
      <div style={{ margin: "16px 16px" }}>
        <Breadcrumb style={{ marginBottom: "20px" }}>
          <Breadcrumb.Item>Backup</Breadcrumb.Item>
          <Breadcrumb.Item>Crea Backup</Breadcrumb.Item>
          <Breadcrumb.Item>
            {this.context.isProd ? "Production" : "Development"}
          </Breadcrumb.Item>
          <Breadcrumb.Item>{this.state.restoreType}</Breadcrumb.Item>
        </Breadcrumb>
        <Divider />

        <Title level={3} style={{ marginBottom: "40px" }}>
          Crea un backup
        </Title>
        <Steps progressDot current={this.state.step}>
          <Step title="1" description="Seleziona la tipologia" />
          <Step title="2 " description="Inserisci dettagli" />
          <Step title="3" description="Conferma" />
        </Steps>
        <Divider />

        <Title level={3}>1. Scegli la tipologia di backup</Title>
        <Space>
          <Button
            onClick={() => this.onBackupTypeChange(SINGLE_COLLECTION)}
            type="primary"
            shape="round"
            icon={<PieChartOutlined />}
            size="large"
          >
            Singola Collection
          </Button>
          <Button
            onClick={() => this.onBackupTypeChange(FULL_DB)}
            type="primary"
            shape="round"
            icon={<DatabaseOutlined />}
            size="large"
          >
            Intero Database
          </Button>
        </Space>

        {this.state.restoreType &&
          this.state.restoreType === SINGLE_COLLECTION && (
            <div style={{ marginTop: "40px" }}>
              <Divider />
              <Space direction="vertical" style={{ width: "100%" }}>
                <Title level={3}>2. Scegli la collection</Title>
                <Select
                  showSearch
                  value={this.state.collection}
                  placeholder="Select a collection"
                  notFoundContent={loading ? <Spin size="small" /> : null}
                  optionFilterProp="children"
                  onChange={this.onCollectionChange}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  style={{ width: "100%" }}
                >
                  {data.map((d) => (
                    <Option key={d.value}>{d.text}</Option>
                  ))}
                </Select>
              </Space>
            </div>
          )}

        {this.state.restoreType && this.state.restoreType === FULL_DB && (
          <div style={{ marginTop: "40px" }}>
            <Title level={3}>2. Nessun dettaglio richiesto</Title>
            <Text>Il backup verr&agrave; fatto dell'intero database.</Text>
          </div>
        )}

        {this.state.step === 2 && (
          <div style={{ marginTop: "40px" }}>
            <Divider />
            <Space direction="vertical">
              <Title level={3}>3. Verifica i dati e lancia il backup</Title>
              {this.context.isProd && (
                <Alert
                  message="Attenzione! Stai per fare un backup dell'ambiente di produzione"
                  type="warning"
                />
              )}
              {this.state.collection && (
                <Text>
                  Collection: <Text strong={true}>{this.state.collection}</Text>
                </Text>
              )}
              {!this.state.collection && (
                <Text>
                  Collection: <Text strong={true}>Tutte</Text>
                </Text>
              )}
              <Text>
                Environment:{" "}
                <Text strong={true}>
                  {this.context.isProd ? "PROD" : "DEV"}
                </Text>
              </Text>
              <Button
                loading={loading}
                onClick={this.createBackup}
                type="primary"
                shape="round"
                icon={<PlayCircleOutlined />}
                size="large"
              >
                Lancia Backup {this.state.restoreType}
              </Button>
            </Space>
          </div>
        )}
      </div>
    );
  }
}

CreateBackup.contextType = AppContext;

export default CreateBackup;
