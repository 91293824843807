import React from "react";
import { Table, Tag } from "antd";
import { Space } from "antd";
import ReactJson from "react-json-view";
import config from "../../api_config.json";
import { useAppContext } from "../../context";
import ParseLogo from "./images/parse.png";

const TabOrgUsers = (props) => {
  const { isProd } = useAppContext();
  const { users } = props;

  const data = users.map((user) => {
    user.key = user.objectId;
    return user;
  });

  const columns = [
    {
      title: "ID",
      dataIndex: "objectId",
      key: "objectId",
    },
    {
      title: "Nome",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "Cognome",
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      title: "Email",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Telefono",
      dataIndex: "telefono",
      key: "telefono",
    },
    {
      title: "Ruolo",
      dataIndex: "ruolo",
      key: "ruolo",
      render: (ruolo) => {
        const colors = {
          Amministratore: "purple",
          Collaboratore: "geekblue",
        };

        return <Tag color={colors[ruolo]}>{ruolo.toUpperCase()}</Tag>;
      },
    },
    {
      title: "Stato",
      dataIndex: "user_status",
      key: "user_status",
      render: (userStatus) => {
        return (
          <Tag color={userStatus ? "green" : "volcano"}>
            {userStatus ? "ATTIVO" : "DISATTIVO"}
          </Tag>
        );
      },
    },
    {
      title: "Links",
      dataIndex: "objectId",
      key: "links",
      render: (userId) => {
        return (
          <Space direction="horizontal">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${
                config.parseDashboard[isProd ? "prod" : "dev"]
              }/browser/_User?filters=[{"field":"objectId","constraint":"eq","compareTo":"${userId}"}]`}
            >
              <img src={ParseLogo} width={20} alt="logo" />
            </a>
          </Space>
        );
      },
    },
  ];

  return (
    <Table
      dataSource={data}
      columns={columns}
      expandable={{
        expandedRowRender: (record) => <ReactJson src={record} name={null} />,
        rowExpandable: (record) => record,
      }}
    />
  );
};

export default TabOrgUsers;
