import React from "react";
import { message, Switch } from "antd";
import "moment/locale/it";
import { useAuth0 } from "../../react-auth0-spa";
import config from "../../api_config.json";
import { useAppContext } from "../../context";

const FieldActiveStarterPlan = (props) => {
  const { org } = props;
  const { getTokenSilently } = useAuth0();
  const { isProd } = useAppContext();

  const updateOrgDetails = async (orgId, fields) => {
    try {
      const token = await getTokenSilently();

      const response = await fetch(
        `${config.baseUrl[isProd ? "prod" : "dev"]}/orgs/${orgId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            fields,
          }),
        }
      );

      await response.text();
      message.success("Fatto");
    } catch (error) {
      message.error("Si é verificato un problema: " + error.message);
      console.error(error);
    }
  };

  const handleChange = (value) => {
    const fields = [
      {
        key: "tipo_licenza",
        value: "partner",
      },
      {
        key: "stato",
        value: "attiva",
      },
      {
        key: "ofc",
        value: true,
      },
      {
        key: "status",
        value: true,
      },
      {
        key: "tipo_istanza",
        value: "production",
      },
      {
        key: "terzista_centro_prenotazioni",
        value: true,
      },
    ];

    updateOrgDetails(org.objectId, fields);
  };

  return (
    <Switch onChange={handleChange} defaultChecked={org.tipo_licenza === "partner"} />
  );
};

export default FieldActiveStarterPlan;
