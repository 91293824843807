import React from "react";
import GiottoAssetOrganiser from './GiottoAssetOrganiser';

const GiottoBackgrounds = () => {
  return (
    <GiottoAssetOrganiser collection="editor_backgrounds_default" fileColName="file" />
  );
};

export default GiottoBackgrounds;
